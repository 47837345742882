import Link from 'next/link'
import { styled } from 'styled-components'

import {
  Breakpoints,
  Colors,
  Shadows,
  Typography,
} from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

export const Card = styled(Link)`
  display: block;
  background: ${designColors.neutral.white};
  transition: box-shadow 0.3s ease;
  width: 14.75rem;
  max-width: 14.75rem;
  overflow: hidden;
  border-radius: 0.5rem;
  &:hover {
    ${Shadows.Hover};
  }

  @media ${Breakpoints.max.medium} {
    width: 100%;
    max-width: 100%;
    ${Shadows.Hover};
  }
`

export const ImageSection = styled.div`
  width: 100%;
  height: 10.5rem;
  position: relative;
  img {
    width: 100%;
    object-fit: cover;
  }
`

export const ImageOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: rgb(20 26 30 / 30%);
  width: 100%;

  b {
    ${Typography.Subtitle1}
    color: ${Colors.Semantic.Neutral.white};
  }
`

export const Tags = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.25rem;
  padding: 0.5rem 0.5rem 0rem 0.5rem;
`

export const SiteDetails = styled.div`
  position: absolute;
  display: flex;
  padding-left: 0.75rem;
  bottom: 0.5rem;
  gap: 0.5rem;
  color: ${Colors.Semantic.Neutral.white};
  font-size: 1rem;
  line-height: 1.5rem;
`

export const SiteLogo = styled.div`
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${Colors.Semantic.Neutral.white};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: contain;
  }
  color: ${Colors.Semantic.Neutral.black};
  font-size: 1rem;
  line-height: 1.5rem;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.75rem;
`

export const Title = styled.p`
  ${Typography.Body2Semibold}
  color: ${Colors.Semantic.Neutral.black};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
`

export const IconP = styled.div<{ $color?: string }>`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  ${Typography.Body3}
  color: ${({ $color }) => $color || Colors.Semantic.Neutral.dark_grey};
`

export const Msrp = styled.p`
  ${Typography.Body3}
  color: ${designColors.neutral.darkGray};
`

export const Bid = styled.p`
  ${Typography.Title2}
  color: ${designColors.neutral.black};
`

export const TimeAndBidCount = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const Divider = styled.span`
  min-height: 0.75rem;
  min-width: 0.0625rem;
  background: ${Colors.Semantic.Neutral.medium_grey};
`

export const Condition = styled.div<{ $color: string }>`
  width: 100%;
  display: flex;

  div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: ${({ $color }) => $color};
    border-radius: 0.25rem;
    gap: 0.25rem;
    padding: 0.1875rem 0.5rem;

    svg {
      flex-shrink: 0;
      font-size: 0.75rem;
      color: ${Colors.Semantic.Neutral.very_dark_grey};
    }

    p {
      ${Typography.Subtitle3}
      margin: 0;
      color: ${Colors.Semantic.Neutral.very_dark_grey};
    }

    span {
      display: flex;
      font-size: 0.75rem;
      line-height: 1.125rem;
      color: ${Colors.Semantic.Neutral.dark_grey};
      letter-spacing: 0.015rem;
    }
  }
`

export const Address = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
