import { useFlag, FeatureFlags } from '@helpers/featureFlags'

import BuyingFormatFilter from './filters/BuyingFormatFilter'
import CategoryFilter from './filters/CategoryFilter'
import ConditionFilter from './filters/ConditionFilter'
import CurrentBidFilter from './filters/CurrentBidFilter'
import DistanceRangeFilter from './filters/DistanceRangeFilter'
import InventoryTypeFilter from './filters/InventoryTypeFilter'
import ListingTypeFilter from './filters/ListingTypeFilter'
import RegionFilter from './filters/RegionFilter'
import SellerFilter from './filters/SellerFilter'
import ShipmentTypeFilter from './filters/ShipmentTypeFilter'
import WarehouseLocationFilter from './filters/WarehouseLocationFilter'
import type { AuctionFilterList } from './types'

// Note: Several "Rating" filters were previously implemented, but the code has since been
//       deleted. Some of this old code might be useful if/when we resurrect those filters.
//       The MR that removed the code can be found here:
//       https://gitlab.bstock.io/b-stock/code/three-mp/fe/buyer-portal/-/merge_requests/715

// TODO MULA-2677 Remove `subcategoriesEnabled` when feature flag is removed
export function getAllListingsFilters(): AuctionFilterList {
  const enableBuyingFormatFilter = useFlag(
    FeatureFlags.ENABLE_RALPH_BUYING_FORMAT_FILTER
  )
  const enableListingTypeFilter = useFlag(
    FeatureFlags.ENABLE_RALPH_LISTING_TYPE_FILTER
  )
  const enableInventoryTypeFilter = useFlag(
    FeatureFlags.ENABLE_RALPH_INVENTORY_TYPE_FILTER
  )
  const enableDistanceFilter = useFlag(
    FeatureFlags.ENABLE_RALPH_DISTANCE_FILTER
  )

  const auctionFilters = [
    ...(enableDistanceFilter ? [DistanceRangeFilter] : []),
    ...(enableListingTypeFilter ? [ListingTypeFilter] : []),
    ...(enableBuyingFormatFilter ? [BuyingFormatFilter] : []),
    RegionFilter,
    WarehouseLocationFilter,
    ConditionFilter,
    ...(enableInventoryTypeFilter ? [InventoryTypeFilter] : []),
    ShipmentTypeFilter,
    CategoryFilter,
    SellerFilter,
    CurrentBidFilter,
  ]

  return auctionFilters
}
