import { useRef } from 'react'

import { faAngleRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { UnstyledButton } from '@b-stock/bstock-next'
import { Button, FormattedMessage } from '@b-stock/bstock-react'
import {
  Colors,
  Shadows,
  Typography,
} from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

import type { AuctionFilterList } from '@components/auctionFilters'
import type { useAuctionSearch } from '@components/AuctionSearchProvider'
import SaveButton from '@components/savedSearch/SaveButton'

import { FiltersList } from './FiltersList'

type AppliedFilterProps = {
  filters: AuctionFilterList
  searchCtx: ReturnType<typeof useAuctionSearch>
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  border-bottom: 0.0625rem solid ${Colors.Semantic.Neutral.medium_grey};
`

const ScrollContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  overflow: auto;
  padding-bottom: 1rem;
  mask-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 1) calc(100% - 3rem),
    rgba(0, 0, 0, 0) 99.5%
  );
`

export const ActiveBar = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CounterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`

const Counter = styled.div`
  ${Typography.Subtitle1}
  color: ${designColors.neutral.black};
`

const ResetBtn = styled(Button)`
  font-size: 0.75rem;
`

const ScrollBtn = styled(UnstyledButton)`
  position: absolute;
  right: 0;
  bottom: -3.75rem;
  width: 2.5rem;
  height: 2.5rem;
  background-color: white;
  ${Shadows.Popovers}
  border-radius: 100%;
  z-index: 9999;
  margin: 0.3125rem;
`

export const AppliedFilters = ({ filters, searchCtx }: AppliedFilterProps) => {
  const { state, updateFilters, getActiveFilterCount, resetFilters } = searchCtx
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const activeFilterCount = getActiveFilterCount()

  const scrollRight = () => {
    const element = scrollContainerRef.current
    if (element) {
      const newPosition = element.scrollLeft + 50
      element.scrollTo({
        left: newPosition,
        behavior: 'smooth',
      })
    }
  }

  if (!activeFilterCount) return null

  return (
    <Wrapper data-testid="applied-filters">
      <ActiveBar>
        <CounterWrapper>
          <Counter>
            <FormattedMessage
              id="Listings.filtersApplied"
              values={{
                count: activeFilterCount.toString(),
              }}
            />
          </Counter>

          <SaveButton />

          <ResetBtn
            appearance="plainTertiary"
            size="small"
            onClick={resetFilters}
          >
            <FormattedMessage id="Listings.clearAll" />
          </ResetBtn>
        </CounterWrapper>
        {
          // These accesses of `scrollContainerRef` aren't safe... but are "good enough" for now
          /* eslint-disable react-compiler/react-compiler */
          scrollContainerRef.current &&
            scrollContainerRef.current.offsetWidth <
              scrollContainerRef.current.scrollWidth && (
              <ScrollBtn onClick={scrollRight}>
                <FontAwesomeIcon icon={faAngleRight} />
              </ScrollBtn>
            )
          /* eslint-enable react-compiler/react-compiler */
        }
      </ActiveBar>
      <ScrollContainer ref={scrollContainerRef}>
        <FiltersList
          filterComponents={filters}
          filtersState={state.filters}
          updateFilters={updateFilters}
        />
      </ScrollContainer>
    </Wrapper>
  )
}
