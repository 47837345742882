'use client'

import { faGavel } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CurrencyStringDollars, FormattedMessage } from '@b-stock/bstock-react'
import { Colors } from '@b-stock/bstock-react/design-system'

import { BidStatus, type SearchResult } from '@queries/listingSearchQuery'

import {
  Bid,
  BidDetailsWrapper,
  ClickToSeePriceText,
  Msrp,
  WinningBidAmount,
  LosingBidAmount,
} from './ui'
import ListingTimeLeft from '../listingTimeLeft/ListingTimeLeft'
import { Divider, IconP, TimeAndBidCount } from '../ui'

const BidDetails = ({
  listing,
  endTime,
}: {
  listing: SearchResult
  endTime: Date
}) => {
  return (
    <BidDetailsWrapper>
      {listing.bidStatus === BidStatus.WINNING ? (
        <WinningBidAmount>
          <FormattedMessage id={'Auction.BidAmount.yourMaxBid'} />:{' '}
          <CurrencyStringDollars
            currency={String(listing.currency)}
            omitDecimal
            value={Number(listing.winningBidAmount)}
          />
        </WinningBidAmount>
      ) : listing.bidStatus === BidStatus.LOSING ? (
        <LosingBidAmount>
          <FormattedMessage id={'Auction.BidAmount.yourMaxBid'} />:{' '}
          <CurrencyStringDollars
            currency={String(listing.currency)}
            omitDecimal
            value={Number(listing.winningBidAmount)}
          />
        </LosingBidAmount>
      ) : (
        <Msrp>
          MSRP{' '}
          <s>
            <CurrencyStringDollars
              currency={String(listing.currency)}
              omitDecimal
              value={Number(listing.retailPrice)}
            />
          </s>
        </Msrp>
      )}
      {listing.bidBasis === 'RELATIVE' ? (
        <ClickToSeePriceText>
          <FormattedMessage id={'Auction.BidAmount.clickToSeePrice'} />
        </ClickToSeePriceText>
      ) : (
        <Bid>
          <CurrencyStringDollars
            currency={String(listing.currency)}
            omitDecimal
            value={Number(listing.winningBidAmount)}
          />
          {/* TODO: Uncomment this once we have the actual value and update value */}
          {/* <span>
          {' '}
          /
          <CurrencyStringDollars
            currency="USD"
            omitDecimal
            value={10} // TODO: Replace with actual value, once implemented.
          />{' '}
          <FormattedMessage id={'Auction.pricePerUnit.title'} />
        </span> */}
        </Bid>
      )}
      <TimeAndBidCount>
        <ListingTimeLeft endTime={endTime} />
        <Divider />
        <IconP $color={Colors.Semantic.Neutral.very_dark_grey}>
          <FontAwesomeIcon icon={faGavel} size="sm" />
          {listing.numberOfBids || 0}
        </IconP>
      </TimeAndBidCount>
    </BidDetailsWrapper>
  )
}

export default BidDetails
