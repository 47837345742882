'use client'

import { useEffect, useState } from 'react'

import { faSlidersH } from '@fortawesome/pro-solid-svg-icons'

import { useDeviceInfo, DeviceType, Breakpoint } from '@b-stock/bstock-next'
import { FormattedMessage } from '@b-stock/bstock-react'

import FiltersTopBar from '@app/all-auctions/FiltersTopBar'
import {
  AllListingsContainer,
  FiltersBarContainer,
  AuctionsContainer,
  ResultsSort,
  TotalListings,
  SortContainer,
  FiltersButton,
  FiltersIcon,
  PaginationContainer,
} from '@app/all-auctions/ui'
import { useFlag, FeatureFlags } from '@helpers/featureFlags'

import { AppliedFilters } from './AppliedFilters'
import SearchFilters, { getAllListingsFilters } from './auctionFilters'
import MobileSearchFilters from './auctionFilters/MobileSearchFilters'
import type { AuctionFiltersState } from './AuctionSearchProvider'
import { useAuctionSearch } from './AuctionSearchProvider'
import ListingGrid from './listingGrid/ListingGrid'
import SavedSearchButton from './savedSearch/SavedSearchButton'
import SortByDropdown from './sortByDropdown/SortByDropdown'

function useIsMobile() {
  const { deviceType, breakpoint } = useDeviceInfo()
  return deviceType !== DeviceType.DESKTOP || breakpoint < Breakpoint.LARGE
}

function FiltersBar({
  showFilterModal,
  closeFilterModal,
  searchCtx,
}: {
  showFilterModal: boolean
  closeFilterModal: () => void
  searchCtx: ReturnType<typeof useAuctionSearch>
}) {
  const isMobile = useIsMobile()

  const shouldRenderMobileFilters = isMobile && showFilterModal
  const shouldRenderDesktopFilters = !isMobile

  const filters = getAllListingsFilters()

  const [filterValues, setFilterValues] = useState<
    Partial<AuctionFiltersState>
  >(searchCtx.state.filters)

  useEffect(
    () => {
      setFilterValues(searchCtx.state.filters)
    },
    // watch shouldRenderMobileFilters so that filterValues gets reset when the
    // mobile filters are opened and closed
    [shouldRenderMobileFilters, searchCtx.state.filters]
  )

  const handleFilterValues = (
    key: keyof AuctionFiltersState,
    values: AuctionFiltersState[keyof AuctionFiltersState]
  ) => {
    setFilterValues((prev) => ({
      ...prev,
      [key]: values,
    }))
  }

  const applyFilters = () => {
    searchCtx.updateFilters(filterValues)
    closeFilterModal()
  }

  return (
    <>
      {shouldRenderMobileFilters ? (
        <MobileSearchFilters
          onSubmit={applyFilters}
          handleFilterValues={handleFilterValues}
          closeModal={closeFilterModal}
          filters={filters}
          searchCtx={searchCtx}
          kind={'auctions'}
          sortComp={<SortByDropdown />}
        />
      ) : null}

      {shouldRenderDesktopFilters ? (
        <SearchFilters filters={filters} searchCtx={searchCtx} />
      ) : null}
    </>
  )
}

function AllAuctionsContent() {
  const searchCtx = useAuctionSearch()

  const { data, error, isLoading, setPage, state } = searchCtx
  const filters = getAllListingsFilters()
  const isMobile = useIsMobile()

  const [showMobileFilterModal, setShowMobileFilterModal] = useState(false)
  const openMobileFilterModal = () => setShowMobileFilterModal(true)
  const closeMobileFilterModal = () => setShowMobileFilterModal(false)

  const enableSavedSearch = useFlag(FeatureFlags.ENABLE_SAVED_SEARCH)

  return (
    <AllListingsContainer>
      <FiltersBarContainer>
        <FiltersBar
          showFilterModal={showMobileFilterModal}
          closeFilterModal={closeMobileFilterModal}
          searchCtx={searchCtx}
        />
      </FiltersBarContainer>
      <AuctionsContainer>
        <FiltersTopBar>
          <AppliedFilters filters={filters} searchCtx={searchCtx} />

          <ResultsSort>
            <TotalListings>
              <FormattedMessage
                id="Listings.results"
                values={{ count: isLoading ? '' : data?.total || 0 }}
              />
            </TotalListings>

            {!isMobile && (
              <SortContainer>
                <SortByDropdown />
              </SortContainer>
            )}
            {isMobile && (
              <>
                {enableSavedSearch && <SavedSearchButton />}
                <FiltersButton onClick={openMobileFilterModal}>
                  <FiltersIcon icon={faSlidersH} />
                  <span>
                    <FormattedMessage id="Filters.Mobile.FiltersButton.title" />
                  </span>
                </FiltersButton>
              </>
            )}
          </ResultsSort>
        </FiltersTopBar>

        <ListingGrid
          error={error}
          isLoading={isLoading}
          listings={data?.listings || []}
          noListings={data?.total === 0}
        />

        {!!data?.total && (
          <PaginationContainer
            page={state.page}
            perPage={data.limit}
            setPage={setPage}
            total={data.total}
          />
        )}
      </AuctionsContainer>
    </AllListingsContainer>
  )
}

export default AllAuctionsContent
