'use client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { UnstyledButton } from '@b-stock/bstock-next'
import { Pagination } from '@b-stock/bstock-react'
import {
  Breakpoints,
  Colors,
  pxToRem,
  Typography,
} from '@b-stock/bstock-react/design-system'

export const AllListingsContainer = styled.main`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 76.5rem;
  align-items: flex-start;
  gap: 1.5rem;
  margin: 2.6875rem auto;

  @media ${Breakpoints.max.medium} {
    flex-direction: column;
    margin: 0;
  }

  @media ${Breakpoints.min.large} {
    max-width: min(76.5rem, -4rem + 100vw);
  }
`

export const FiltersBarContainer = styled.div`
  display: flex;
  padding-bottom: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  @media ${Breakpoints.max.medium} {
    width: 100%;
    padding: 0;
  }
`

export const AuctionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  flex-grow: 1;
  min-width: 0; // prevents child content that is too wide (possible with AppliedFilters#ScrollContainer) from stretching the width

  @media ${Breakpoints.max.medium} {
    width: 100%;
    padding: 0 3rem 1.5rem 3rem;
    gap: 1.5rem;
  }

  @media ${Breakpoints.max.small} {
    width: 100%;
    padding: 0 1.5rem;
    gap: 1.5rem;
  }
`

export const ResultsSort = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-height: 2.25rem;
  gap: 1.5rem;
`

export const TotalListings = styled.h1`
  ${Typography.Title2}
  flex-grow: 1;
`

export const SortContainer = styled.div`
  flex-shrink: 0;
`

export const FiltersButton = styled(UnstyledButton)`
  ${Typography.Subtitle2}
  color: ${Colors.Semantic.BStock.default.lightBackground};
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-self: end;
  gap: 0.5rem;
  padding: 0.5625rem 0.75rem;
  border-radius: 0.25rem;
  border: ${pxToRem(1)} solid ${Colors.Semantic.BStock.default.lightBackground};
`

export const FiltersIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
`

export const PaginationContainer = styled(Pagination)`
  margin: 0;
`
