'use client'

import { FormattedMessage, Tag, TagType } from '@b-stock/bstock-react'

import { BidStatus, type SearchResult } from '@queries/listingSearchQuery'

const ListingTag = ({ listing }: { listing: SearchResult }) => {
  if (listing.bidStatus === BidStatus.WINNING)
    return (
      <Tag type={TagType.success}>
        <FormattedMessage id={'Auction.tag.winning'} />
      </Tag>
    )
  if (listing.bidStatus === BidStatus.LOSING)
    return (
      <Tag type={TagType.error}>
        <FormattedMessage id={'Auction.tag.losing'} />
      </Tag>
    )
  return null
}

export default ListingTag
