import { useState } from 'react'
import type * as React from 'react'

import { faAngleUp, faAngleDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { UnstyledButton } from '@b-stock/bstock-next'
import { FormattedMessage } from '@b-stock/bstock-react'
import {
  Breakpoints,
  Colors,
  Typography,
} from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

type ExpandContainerProps = {
  expandedOnInit: boolean
  onExpand?: () => void
  afterExpandAnimation?: (target: HTMLButtonElement) => void
}

type FilterContainerProps = {
  label: string
  children: React.ReactNode
} & ExpandContainerProps

const Container = styled.div`
  border-top: 1px solid ${designColors.neutral.mediumGray};
  min-width: 11.5rem;
  display: grid;
  gap: 0.75rem;
  padding: 1.5rem 0;

  @media ${Breakpoints.min.large} {
    width: 11.5rem !important;
  }
`

const LabelContainer = styled(UnstyledButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const FilterLabel = styled.span`
  ${Typography.Subtitle2}
  display: block;
  color: ${Colors.Semantic.Neutral.black};
`

const FilterContentContainer = styled.div`
  & > div:last-child {
    border-bottom: none;
  }
  & > input {
    box-sizing: border-box;
  }
`

export const useExpandContainer = ({
  expandedOnInit,
  afterExpandAnimation,
  onExpand,
}: ExpandContainerProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(expandedOnInit)

  const handleExpand = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { currentTarget } = e
    if (!isExpanded && onExpand) {
      onExpand()
    }
    setIsExpanded(!isExpanded)
    if (!isExpanded && afterExpandAnimation) {
      setTimeout(() => {
        afterExpandAnimation(currentTarget)
      }, 0)
    }
  }

  return { isExpanded, handleExpand }
}

const FilterContainer = ({
  afterExpandAnimation,
  label,
  children,
  expandedOnInit,
  onExpand,
}: FilterContainerProps): React.JSX.Element => {
  const { isExpanded, handleExpand } = useExpandContainer({
    afterExpandAnimation,
    expandedOnInit,
    onExpand,
  })

  return (
    <Container>
      <LabelContainer onClick={handleExpand}>
        <FilterLabel>
          <FormattedMessage id={label} />
        </FilterLabel>
        <FontAwesomeIcon icon={isExpanded ? faAngleUp : faAngleDown} />
      </LabelContainer>
      {isExpanded && (
        <FilterContentContainer>{children}</FilterContentContainer>
      )}
    </Container>
  )
}

export default FilterContainer
