'use client'

import { useState } from 'react'

import { faHeart } from '@fortawesome/pro-regular-svg-icons'
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { Button, FormattedMessage } from '@b-stock/bstock-react'

import { FeatureFlags, useFlag } from '@helpers/featureFlags'

const Icon = styled(FontAwesomeIcon)`
  font-size: 0.75rem;
`

const Save = styled(Button).attrs({
  appearance: 'plainTertiary',
  size: 'small',
  type: 'button',
})`
  font-size: 0.75rem;
`

// TODO: [WRH-239] Integration: Make "Save" button save the current search
const SaveButton = () => {
  // TODO: [WRH-262] Cleanup: Remove Saved Search feature flag logic after release
  const isSavedSearchEnabled = useFlag(FeatureFlags.ENABLE_SAVED_SEARCH)

  const [isSaved, setIsSaved] = useState(false)

  if (!isSavedSearchEnabled) {
    return null
  }

  return (
    <Save onClick={() => setIsSaved(!isSaved)}>
      <Icon icon={isSaved ? faHeartSolid : faHeart} />
      <FormattedMessage id={isSaved ? 'Listings.saved' : 'Listings.save'} />
    </Save>
  )
}

export default SaveButton
