import { useRef, useState } from 'react'
import type * as React from 'react'

import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { UnstyledButton } from '@b-stock/bstock-next'
import { Button, Modal, FormattedMessage } from '@b-stock/bstock-react'
import { Shadows } from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

import type {
  AuctionFiltersState,
  AuctionSearchContextValue,
} from '@components/AuctionSearchProvider'
import StyledScroll from '@components/common/modal/scrollbarStyles'

import FilterContainer from './shared/FilterContainer'
import ShowResultsButton from './shared/ShowResultsButton'
import type {
  ContextType,
  Filter,
  SelectedFilterType,
  AuctionFilterList,
} from './types'

const ModalActions = styled.div`
  ${Shadows.Top};
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
`

export const SModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: fit-content;
  overflow-x: auto;
  padding: 1rem;
  ${StyledScroll}
`

export const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: ${designColors.primary.default};
`

export const LeftArrow = styled(UnstyledButton)`
  margin-right: 1rem;
`

export const StyledModal = styled(Modal)`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: fit-content;
  max-height: calc(100% - 2rem);
`

export const BackButton = ({
  onPress,
}: {
  onPress: () => void
}): React.ReactElement => (
  <LeftArrow onClick={onPress}>
    <StyledIcon icon={faAngleLeft} />
  </LeftArrow>
)

type ModalContentProps<ContextValue extends ContextType, T> = {
  containerRef?: React.RefObject<HTMLDivElement>
  selectedFilter: string
  selectFilter: (filter: SelectedFilterType) => void
  filterShouldDisplaySeparately: boolean
  filters: Filter<T, ContextValue>[]
  searchCtx: ContextValue
  onFilterChange: (key: keyof AuctionFiltersState, values: T) => void
}

const ModalContent = <ContextValue extends ContextType, T>({
  containerRef,
  selectedFilter,
  selectFilter,
  filterShouldDisplaySeparately,
  filters: modalFilters,
  searchCtx,
  onFilterChange,
}: ModalContentProps<ContextValue, T>) => {
  const handleClick = (target: HTMLButtonElement) => {
    if (containerRef?.current) {
      containerRef.current.scrollTo({
        top: target.offsetTop - 84,
        behavior: 'smooth',
      })
    }
  }

  if (selectedFilter && filterShouldDisplaySeparately) {
    const FilterComp = modalFilters.find(
      (Filter) => Filter.name === selectedFilter
    )

    if (!FilterComp) {
      throw new Error(
        `Unable to find component with name "${selectedFilter}" in "filters".`
      )
    }
    const props = FilterComp.getOwnProps(searchCtx)

    const handleChange = (value: T) => {
      onFilterChange(
        props.filterKey,
        (value instanceof Set ? [...value] : value) as T
      )
    }

    return (
      <FilterComp {...props} onChange={handleChange} desktopScreen={false} />
    )
  } else {
    return (
      <div>
        {modalFilters.map((FilterComp) => {
          const label = FilterComp.label
          const name = FilterComp.name
          const props = FilterComp.getOwnProps(searchCtx)

          const shouldDisplayFilter =
            Object.keys(props.availableItems || {}).length ||
            Object.keys(props.value || {}).length ||
            Object.keys(props.items || {}).length

          const handleChange = (value: T) => {
            onFilterChange(
              props.filterKey,
              (value instanceof Set ? [...value] : value) as T
            )
          }

          const handleExpand = () => {
            selectFilter({
              filterLabel: label,
              filterName: name,
              itemsCount: props.items ? Object.keys(props.items).length : 0,
            })
          }

          return shouldDisplayFilter ? (
            <FilterContainer
              key={FilterComp.label}
              label={FilterComp.label}
              expandedOnInit={false}
              onExpand={handleExpand}
              afterExpandAnimation={handleClick}
            >
              <FilterComp
                {...props}
                onChange={handleChange}
                desktopScreen={false}
              />
            </FilterContainer>
          ) : null
        })}
      </div>
    )
  }
}

type FilterModalProps = {
  kind: 'auctions'
  onClose?: () => void
  onSubmit?: () => void
  closeModal: () => void
  handleFilterValues: (
    key: keyof AuctionFiltersState,
    values: AuctionFiltersState[keyof AuctionFiltersState]
  ) => void
  filters: AuctionFilterList
  searchCtx: AuctionSearchContextValue
  sortComp?: React.ReactNode
}

const MobileSearchFilters: React.FC<FilterModalProps> = ({
  onClose,
  onSubmit,
  closeModal,
  handleFilterValues,
  sortComp,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const handleSubmit = () => {
    onSubmit?.()
    closeModal()
  }

  const handleCancel = () => {
    onClose?.()
    closeModal()
  }

  const [selectedFilter, setSelectedFilter] = useState<SelectedFilterType>({
    filterLabel: '',
    filterName: '',
    itemsCount: 0,
  })

  const selectFilter = ({
    filterLabel,
    filterName,
    itemsCount,
  }: SelectedFilterType) => {
    setSelectedFilter({ filterLabel, filterName, itemsCount })
  }
  const handleBackButtonClick = () => {
    setSelectedFilter({
      filterLabel: '',
      filterName: '',
      itemsCount: 0,
    })
  }

  const filterShouldDisplaySeparately = !!(
    selectedFilter.filterName && selectedFilter.itemsCount > 8
  )

  return (
    <StyledModal closeModal={closeModal}>
      <Modal.Header>
        {filterShouldDisplaySeparately ? (
          <>
            <BackButton onPress={handleBackButtonClick} />
            <FormattedMessage id={selectedFilter.filterLabel} />
          </>
        ) : (
          <FormattedMessage
            id="Filters.MobileSearchFilters.header"
            defaultMessage="Filters.MobileSearchFilters.header"
          />
        )}
      </Modal.Header>
      <SModalContent ref={containerRef}>
        {!filterShouldDisplaySeparately && sortComp}
        {props.kind === 'auctions' ? (
          <ModalContent
            filterShouldDisplaySeparately={filterShouldDisplaySeparately}
            selectedFilter={selectedFilter.filterName}
            selectFilter={selectFilter}
            filters={props.filters || []}
            containerRef={containerRef}
            searchCtx={props.searchCtx}
            onFilterChange={handleFilterValues}
          />
        ) : (
          <ModalContent
            filterShouldDisplaySeparately={filterShouldDisplaySeparately}
            selectedFilter={selectedFilter.filterName}
            selectFilter={selectFilter}
            filters={props.filters || []}
            containerRef={containerRef}
            searchCtx={props.searchCtx}
            onFilterChange={handleFilterValues}
          />
        )}
      </SModalContent>
      <ModalActions>
        <ButtonContainer>
          <Button type="button" appearance="tertiary" onClick={handleCancel}>
            <FormattedMessage
              id="Filters.MobileSearchFilters.actions.cancel"
              defaultMessage="Filters.MobileSearchFilters.actions.cancel"
            />
          </Button>
          <ShowResultsButton
            onSubmit={handleSubmit}
            loading={false}
            results={props.searchCtx.data?.total.toString() || '0'}
          />
        </ButtonContainer>
      </ModalActions>
    </StyledModal>
  )
}

export default MobileSearchFilters
