import { styled } from 'styled-components'

import { Shadows } from '@b-stock/bstock-react/design-system'

import StyledScroll from '@components/common/modal/scrollbarStyles'

type ModalContent = {
  $size?: 'default' | 'tall'
}

export const ModalContent = styled.div<ModalContent>`
  max-height: ${({ $size }) => ($size === 'tall' ? 50 : 25)}dvh;
  overflow: auto;
  padding: 1.5rem 3rem;

  ${StyledScroll}
`

export const ModalFooter = styled.div`
  ${Shadows.Top};
`
