import { useIntl } from 'react-intl'

import CategoricalFilter from '../../shared/CategoricalFilter'
import {
  useFilterOptions,
  createCategoricalAppliedComponent,
} from '../../shared/filterUtils'
import type { AuctionFilter } from '../../types'

const toDisplayName = (name: string) => {
  // filter out item id and in some cases integ id 'i-' from string for the display name
  // '6647b7b6984775b0ce58c873-A Micro Center LLC' will return ['6647b7b6984775b0ce58c873-A Micro Center LLC', 'A Micro Center LLC']
  // 'ach-i-acehardware (Integ)' will return ['ach-i-acehardware (Integ)', 'acehardware (Integ)']
  const regex = /^[^-]+-(?:i-)?(.+)$/
  const match = name.match(regex)

  return match ? match[1] : name
}

const SellerFilter: AuctionFilter<Set<string>> = ({
  onChange,
  desktopScreen,
  items: itemsData,
  availableItems: availableItemsData,
  value,
}) => {
  const intl = useIntl()
  const items = useFilterOptions(itemsData, toDisplayName)
  const availableItems = useFilterOptions(availableItemsData, toDisplayName)

  return (
    <CategoricalFilter
      items={items}
      availableItems={availableItems}
      name={intl.formatMessage({
        id: 'Filters.sellers',
      })}
      selected={value}
      onChange={onChange}
      desktopScreen={desktopScreen}
    />
  )
}

SellerFilter.getOwnProps = ({ state, data, updateFilters }) => {
  return {
    filterKey: 'storefront',
    items: data?.allFilters?.storefront || [],
    value: new Set(state.filters.storefront),
    onChange: (value: Set<string>) => updateFilters({ storefront: [...value] }),
  }
}

SellerFilter.label = 'Filters.sellers'

SellerFilter.Applied = createCategoricalAppliedComponent(
  'storefront',
  SellerFilter.label,
  (seller: string) => <span>{toDisplayName(seller)}</span>
)

export default SellerFilter
