import { useState, useMemo, useCallback } from 'react'
import type * as React from 'react'

import { faAngleUp, faAngleDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { UnstyledButton } from '@b-stock/bstock-next'
import { Breakpoints } from '@b-stock/bstock-react/design-system'

import StyledScroll from '@components/common/modal/scrollbarStyles'
import { useAnalytics } from '@helpers/telemetry/SegmentAnalytics'

import OnlyableCheckbox from './OnlyableCheckbox'

// TODO: convert px to rem

type InternalNodeState = 'FULL' | 'EMPTY' | 'PARTIAL'
type InternalNodeStateMap = Partial<Record<string, InternalNodeState>>

type InternalNode = {
  nodeType: 'INTERNAL' | 'LEAF'
  key: string
  displayName: string
  children: InternalNode[]
}

type Props = {
  label?: string
  nodes: InternalNode[]
  selected: Set<string> | string | null
  setSelected: (value: Set<string>) => void
  shouldNodeBeExpanded?: (node: InternalNode) => boolean
  desktopScreen?: boolean
}

type InternalNodeComponentProps = {
  label?: string
  node: InternalNode
  state: InternalNodeState
  toggleSelected: (key: string, setOnly?: boolean) => void
  toggleInternal: (key: string, setOnly?: boolean) => void
  selected: Set<string> | string | null
  initialECState?: boolean
  nodeStates: InternalNodeStateMap
}

const NodeList = styled.ol`
  list-style: none;
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  ${StyledScroll}

  @media ${Breakpoints.min.medium} {
    width: 11.5rem;
    overflow: hidden;
  }
`

const LeafNodeList = styled.ol`
  list-style: none;
  padding: 0 0 0 0.5rem;
  margin: 12px 0 0;
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  width: 100%;
  overflow: hidden;

  &:not(:has(li > div > button)) {
    padding-left: 1.75rem;
  }
`

const InternalHeading = styled.div`
  display: flex;
  width: 100%;
`

const ECButton = styled(UnstyledButton)`
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`

const InternalNodeComponent: React.FC<InternalNodeComponentProps> = ({
  label,
  node,
  state,
  selected,
  toggleSelected,
  toggleInternal,
  initialECState,
  nodeStates,
}) => {
  const [isExpanded, setIsExpanded] = useState(initialECState)
  const toggleExpanded = useCallback(() => setIsExpanded((v) => !v), [])

  const { trackButtonClicked } = useAnalytics()

  const handleAuctionsFilterClick = (title: string, name: string) => {
    trackButtonClicked(
      'all_auction', // screen_name
      'all_auction_filters', // button_name
      'home_portal', // source
      'buyer', // entity_type
      {
        device: 'Desktop',
        filter_title: title,
        filter_name: name,
      }
    )
  }

  return (
    <li>
      <InternalHeading>
        {node.children.length > 0 ? (
          <>
            <ECButton onClick={toggleExpanded}>
              <FontAwesomeIcon icon={isExpanded ? faAngleUp : faAngleDown} />
            </ECButton>

            <OnlyableCheckbox
              as="div"
              displayName={node.displayName}
              checked={state === 'PARTIAL' ? 'PARTIAL' : state === 'FULL'}
              onChange={() => toggleInternal(node.key)}
              onOnly={() => toggleInternal(node.key, true)}
            />
          </>
        ) : (
          <OnlyableCheckbox
            as="div"
            displayName={node.displayName}
            checked={
              !!(
                selected &&
                typeof selected !== 'string' &&
                selected.has(node.key)
              )
            }
            onChange={() => toggleSelected(node.key)}
            onOnly={() => toggleSelected(node.key, true)}
            onFilterClick={() =>
              handleAuctionsFilterClick(label ?? '', node.displayName)
            }
          />
        )}
      </InternalHeading>

      {isExpanded && node.children.length > 0 && (
        <LeafNodeList>
          {node.children.map((lNode) => (
            <InternalNodeComponent
              key={lNode.key}
              label={label}
              state={nodeStates ? nodeStates[lNode.key] || state : state}
              node={lNode}
              nodeStates={nodeStates}
              selected={selected}
              toggleSelected={toggleSelected}
              toggleInternal={toggleInternal}
              initialECState={initialECState}
            />
          ))}
        </LeafNodeList>
      )}
    </li>
  )
}

const HierarchicalCategoricalFilter: React.FC<Props> = ({
  label,
  nodes,
  selected,
  setSelected,
  shouldNodeBeExpanded,
  desktopScreen,
}) => {
  const [updatedFilters, setUpdatedFilters] = useState<
    Set<string> | string | null
  >(selected)

  // Helper function to find a node by its key in the tree
  const findNodeByKey = useCallback(
    (nodeList: InternalNode[], key: string): InternalNode | null => {
      for (const node of nodeList) {
        if (node.key === key) return node
        const childResult = findNodeByKey(node.children, key)
        if (childResult) return childResult
      }
      return null
    },
    []
  )

  const getSelectedNodes = useMemo(() => {
    const set = desktopScreen ? selected : updatedFilters
    const selectedNodes = new Set([
      ...(typeof set !== 'string' && set ? set : []),
    ])

    const addParents = (node: InternalNode) => {
      const selectedChildrenCount = node.children.reduce(
        (acc, child) => acc + (selectedNodes.has(child.key) ? 1 : 0),
        0
      )

      if (selectedChildrenCount > 0) {
        selectedNodes.add(node.key)
      }

      node.children.forEach(addParents)
    }

    nodes.forEach(addParents)
    return selectedNodes
  }, [nodes, selected])

  const internalNodeStates: InternalNodeStateMap = useMemo(() => {
    const internalNodeStates: InternalNodeStateMap = {}

    const computeState = (node: InternalNode) => {
      const selectedChildrenCount = node.children.reduce(
        (acc, child) => acc + (getSelectedNodes.has(child.key) ? 1 : 0),
        0
      )

      if (selectedChildrenCount === 0) {
        internalNodeStates[node.key] = 'EMPTY'
      } else if (selectedChildrenCount === node.children.length) {
        internalNodeStates[node.key] = 'FULL'
      } else {
        internalNodeStates[node.key] = 'PARTIAL'
      }

      node.children.forEach(computeState)
    }
    nodes.forEach(computeState)
    return internalNodeStates
  }, [nodes, getSelectedNodes])

  const toggleSelected = useCallback(
    (key: string, setOnly?: boolean) => {
      const set = desktopScreen ? selected : updatedFilters
      const clone = new Set([...(typeof set !== 'string' && set ? set : [])])

      if (setOnly) {
        clone.clear()
      }

      const iNode = findNodeByKey(nodes, key)

      if (iNode) {
        const toggleDescendents = (node: InternalNode) => {
          node.children.forEach(toggleDescendents)
          if (clone.has(node.key) && !setOnly) {
            clone.delete(node.key)
          } else {
            clone.add(node.key)
          }
        }
        toggleDescendents(iNode)
      } else if (clone.has(key) && !setOnly) {
        clone.delete(key)
      } else {
        clone.add(key)
      }
      setSelected(clone)

      if (!desktopScreen) {
        setUpdatedFilters(clone)
      }
    },
    [selected, setSelected, nodes, findNodeByKey]
  )

  const toggleInternal = useCallback(
    (key: string, setOnly?: boolean) => {
      const set = desktopScreen ? selected : updatedFilters
      const clone = new Set([...(typeof set !== 'string' && set ? set : [])])

      if (setOnly) {
        clone.clear()
      }

      const iNode = findNodeByKey(nodes, key)

      if (!iNode) return

      const addDescendents = (node: InternalNode) => {
        if (!node.children.length) {
          clone.add(node.key)
        }
        node.children.forEach(addDescendents)
      }

      const removeDescendents = (node: InternalNode) => {
        clone.delete(node.key)
        node.children.forEach(removeDescendents)
      }

      if (internalNodeStates[iNode.key] !== 'FULL' || setOnly) {
        addDescendents(iNode)
      } else {
        removeDescendents(iNode)
      }

      setSelected(clone)

      if (!desktopScreen) {
        setUpdatedFilters(clone)
      }
    },
    [selected, setSelected, nodes, internalNodeStates, findNodeByKey]
  )

  return (
    <NodeList>
      {nodes.map((iNode) => (
        <InternalNodeComponent
          key={iNode.key}
          label={label}
          state={internalNodeStates[iNode.key] || 'EMPTY'}
          node={iNode}
          nodeStates={internalNodeStates}
          selected={desktopScreen ? selected : updatedFilters}
          toggleSelected={toggleSelected}
          toggleInternal={toggleInternal}
          initialECState={
            shouldNodeBeExpanded ? shouldNodeBeExpanded(iNode) : false
          }
        />
      ))}
    </NodeList>
  )
}

export default HierarchicalCategoricalFilter

export type { InternalNode }
