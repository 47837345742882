import { useIntl } from 'react-intl'

import { ListingEnums } from '@b-stock/bstock-react/locale'
import type { ListingDtoListingTypeEnum } from '@b-stock/listing-api-client'

import CategoricalFilter from '../../shared/CategoricalFilter'
import {
  useFilterOptions,
  createCategoricalAppliedComponent,
} from '../../shared/filterUtils'
import type { AuctionFilter } from '../../types'

const toDisplayName = (option: string) => (
  <ListingEnums.ListingType.Component
    // We know the option will be an enum value in practice, even though
    // the API doesn't strictly enforce it. If it ever isn't, ListingEnums
    // should gracefully handle this and just output the raw option string.
    value={option as ListingDtoListingTypeEnum}
  />
)

const ListingTypeFilter: AuctionFilter<Set<string>> = ({
  onChange,
  desktopScreen,
  items: itemsData,
  availableItems: availableItemsData,
  value,
}) => {
  const intl = useIntl()
  const items = useFilterOptions(itemsData, toDisplayName)
  const availableItems = useFilterOptions(availableItemsData, toDisplayName)

  return (
    <CategoricalFilter
      items={items}
      availableItems={availableItems}
      selected={value}
      name={intl.formatMessage({
        id: 'Filters.listingType',
      })}
      onChange={onChange}
      desktopScreen={desktopScreen}
    />
  )
}

ListingTypeFilter.getOwnProps = ({ state, data, updateFilters }) => {
  return {
    filterKey: 'listingType',
    items: data?.allFilters?.listingType || [],
    value: new Set(state.filters.listingType),
    onChange: (value: Set<string>) =>
      updateFilters({ listingType: [...value] }),
  }
}

ListingTypeFilter.label = 'Filters.listingType'

ListingTypeFilter.Applied = createCategoricalAppliedComponent(
  'listingType',
  ListingTypeFilter.label,
  toDisplayName
)

export default ListingTypeFilter
