import { AllListingsSortOrderEnum } from '@b-stock/search-api-client'
import type { AllListingsSortByEnum } from '@b-stock/search-api-client'

export const validateSortBy = (
  s: string | string[] | undefined
): AllListingsSortByEnum => {
  if (typeof s !== 'string' || !sortByValues.has(s as AllListingsSortByEnum))
    return 'endTime'

  return s as AllListingsSortByEnum
}

export const validateSortOrder = (
  s: string | string[] | undefined
): AllListingsSortOrderEnum => {
  if (typeof s !== 'string' || !sortOrderValues.has(s)) return 'asc'

  return s as AllListingsSortOrderEnum
}

export const sortByValues = new Set<AllListingsSortByEnum>([
  'endTime',
  'startTime',
  'winningBidAmount',
  'numberOfBids',
])

export const sortOrderValues = new Set<string>(
  Object.values(AllListingsSortOrderEnum)
)
