import { useState } from 'react'

import { styled } from 'styled-components'

import { useDeviceInfo, DeviceType, Breakpoint } from '@b-stock/bstock-next'
import { Button, FormattedMessage } from '@b-stock/bstock-react'
import {
  Breakpoints,
  Colors,
  Typography,
} from '@b-stock/bstock-react/design-system'

import { FeatureFlags, useFlag } from '@helpers/featureFlags'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.75rem;
  border-top: 1px solid ${Colors.Semantic.Neutral.medium_grey};
  padding: 1.5rem 0;

  button {
    justify-content: flex-start;
  }

  @media ${Breakpoints.min.large} {
    width: 11.5rem;
  }

  @media ${Breakpoints.max.medium} {
    ${Typography.Subtitle3}
    color: ${Colors.Semantic.BStock.default.lightBackground};
    padding: 0;
    border-top: none;
    text-align: center;
  }
`

const Heading = styled.h4`
  ${Typography.Subtitle2}
  display: block;
  color: ${Colors.Semantic.Neutral.black};
`

const CurrentSearch = styled.div`
  display: flex;
  gap: 0.75rem;

  p {
    ${Typography.Body3}
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const SwitchButton = styled(Button).attrs({
  appearance: 'plainTertiary',
  size: 'small',
  type: 'button',
})`
  font-size: 0.75rem;
`

// TODO: [WRH-246] Populate with real data and have buttons open the Saved Search modal
const SavedSearchButton = () => {
  const { deviceType, breakpoint } = useDeviceInfo()

  // TODO: [WRH-262] Cleanup: Remove Saved Search feature flag logic after release
  const isSavedSearchEnabled = useFlag(FeatureFlags.ENABLE_SAVED_SEARCH)

  const [hasCurrentSearch, setHasCurrentSearch] = useState(false)

  const isPhone =
    deviceType === DeviceType.PHONE || breakpoint < Breakpoint.MEDIUM
  const isTablet =
    deviceType === DeviceType.TABLET ||
    (breakpoint > Breakpoint.SMALL && breakpoint < Breakpoint.LARGE)

  if (!isSavedSearchEnabled) {
    return null
  }

  const openModal = () => setHasCurrentSearch((prev) => !prev)

  const currentSearchName =
    'Nike Shoes, Apparel, Shoes & Accessories, BestBuy, Costco, LTL'
  const count = 2

  return (
    <>
      <Container>
        {hasCurrentSearch ? (
          <>
            <Heading>
              <FormattedMessage id="SavedSearch.savedSearch" />
            </Heading>
            <CurrentSearch>
              <p>{currentSearchName}</p>

              <SwitchButton onClick={openModal}>
                <FormattedMessage id="SavedSearch.switch" />
              </SwitchButton>
            </CurrentSearch>
          </>
        ) : (
          <Button
            appearance="plainTertiary"
            size="small"
            type="button"
            onClick={openModal}
          >
            {isPhone && (
              <FormattedMessage
                id="SavedSearch.savedSrch"
                values={{ count: count.toString() }}
              />
            )}
            {isTablet && (
              <FormattedMessage
                id="SavedSearch.savedSearches"
                values={{ count: count.toString() }}
              />
            )}
            {!isPhone && !isTablet && (
              <FormattedMessage
                id="SavedSearch.savedSearches"
                values={{
                  count: count.toString(),
                }}
              />
            )}
          </Button>
        )}
      </Container>
    </>
  )
}

export default SavedSearchButton
