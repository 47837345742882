'use client'

import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { styled } from 'styled-components'

import { EmptyState, FormattedMessage } from '@b-stock/bstock-react'
import { Breakpoints } from '@b-stock/bstock-react/design-system'

import AuctionCardSkeleton from '@components/common/auctionCard/AuctionCardSkeleton'
import ListingCard from '@components/listingCard/ListingCard'
import type { SearchResult } from '@queries/listingSearchQuery'

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 1.5rem;
  row-gap: 3rem;

  @media ${Breakpoints.max.medium} {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(14.75rem, 1fr));
    gap: 1.5rem;
  }
`

type ListingGridProps = {
  error?: Error | null
  isLoading: boolean
  listings: SearchResult[]
  noListings: boolean
}

const ListingGrid = ({
  error,
  isLoading,
  listings,
  noListings,
}: ListingGridProps) => {
  return (
    <>
      {isLoading ? (
        <Grid>
          {Array.from({ length: 24 }, (_, k) => k).map((k) => (
            <AuctionCardSkeleton key={k} />
          ))}
        </Grid>
      ) : error ? (
        <div>Error: {error.message}</div>
      ) : noListings ? (
        <EmptyState
          icon={faSearch}
          title={<FormattedMessage id="Listings.noResults" />}
        />
      ) : (
        <Grid>
          {listings.map((listing) => (
            <ListingCard key={listing.id} listing={listing} />
          ))}
        </Grid>
      )}
    </>
  )
}

export default ListingGrid
