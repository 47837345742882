'use client'

import type * as React from 'react'

import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { UnstyledButton } from '@b-stock/bstock-next'
import { FormattedMessage } from '@b-stock/bstock-react'
import { Colors, Typography } from '@b-stock/bstock-react/design-system'

export type RecordType<T> = {
  key: T
  name: React.ReactNode
}

type Props<T> = {
  label: string
  values: RecordType<T>[]
  onRemove: (key: T) => void
  noClose?: boolean
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  min-width: max-content;
`

const SectionTitle = styled.div`
  ${Typography.Body3}
  color: ${Colors.Semantic.Neutral.dark_grey};
`

const SectionValueContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`

const SectionValue = styled.div`
  display: flex;
  align-items: center;
  ${Typography.Body4};
  color: ${Colors.Semantic.Neutral.black};
  padding: 0 0.5rem 0 1rem;
  border-radius: 6.25rem;
  border: 0.0625rem solid ${Colors.Semantic.Neutral.medium_grey};
`

const RemoveBtn = styled(UnstyledButton)`
  padding: 0.5rem;

  svg {
    color: ${Colors.Semantic.Neutral.dark_grey};
    font-size: 0.75rem;
  }
`

const AppliedFilterSection = <T extends React.Key>({
  label,
  onRemove,
  values,
  noClose,
}: Props<T>) => {
  if (values.length === 0) return null
  return (
    <Section>
      <SectionTitle>
        <FormattedMessage id={label} />
      </SectionTitle>
      <SectionValueContainer>
        {values.map((item: RecordType<T>) => (
          <SectionValue key={item.key}>
            {item.name}
            {!noClose && (
              <RemoveBtn role="remove-btn" onClick={() => onRemove(item.key)}>
                <FontAwesomeIcon icon={faTimes} />
              </RemoveBtn>
            )}
          </SectionValue>
        ))}
      </SectionValueContainer>
    </Section>
  )
}

export default AppliedFilterSection
