import { useIntl } from 'react-intl'

import { SearchEnums } from '@b-stock/bstock-react/locale'
import type { InventoryTypeEnum } from '@b-stock/search-api-client'

import CategoricalFilter from '../../shared/CategoricalFilter'
import {
  useFilterOptions,
  createCategoricalAppliedComponent,
} from '../../shared/filterUtils'
import type { AuctionFilter } from '../../types'

const toDisplayName = (option: string) => (
  <SearchEnums.InventoryType.Component
    // We know the option will be an enum value in practice, even though
    // the API doesn't strictly enforce it. If it ever isn't, ListingEnums
    // should gracefully handle this and just output the raw option string.
    value={option as InventoryTypeEnum}
  />
)

const InventoryTypeFilter: AuctionFilter<Set<string>> = ({
  onChange,
  desktopScreen,
  items: itemsData,
  availableItems: availableItemsData,
  value,
}) => {
  const intl = useIntl()
  const items = useFilterOptions(itemsData, toDisplayName)
  const availableItems = useFilterOptions(availableItemsData, toDisplayName)

  return (
    <CategoricalFilter
      items={items}
      availableItems={availableItems}
      selected={value}
      name={intl.formatMessage({
        id: 'Filters.inventoryType',
      })}
      onChange={onChange}
      desktopScreen={desktopScreen}
    />
  )
}

InventoryTypeFilter.getOwnProps = ({ state, data, updateFilters }) => {
  return {
    filterKey: 'inventoryType',
    items: data?.allFilters.inventoryType || [],
    value: new Set(state.filters.inventoryType),
    onChange: (value: Set<string>) =>
      updateFilters({ inventoryType: [...value] }),
  }
}

InventoryTypeFilter.label = 'Filters.inventoryType'

InventoryTypeFilter.Applied = createCategoricalAppliedComponent(
  'inventoryType',
  InventoryTypeFilter.label,
  toDisplayName
)

export default InventoryTypeFilter
