'use client'
import { useCallback, useMemo } from 'react'

import { styled } from 'styled-components'

import { DetailedDropdown } from '@b-stock/bstock-react'
import { designColors } from '@b-stock/bstock-react/theme'
import type {
  AllListingsSortByEnum,
  AllListingsSortOrderEnum,
} from '@b-stock/search-api-client'

import { useAuctionSearch } from '@components/AuctionSearchProvider/AuctionSearchProvider'

export const StyledDropdown = styled(DetailedDropdown)`
  width: 19.75rem;
  div {
    color: ${designColors.neutral.black};
    white-space: nowrap;
  }
  div > div > div {
    color: ${designColors.neutral.darkGray};
  }
  select {
    padding: 0.375rem;
    padding-right: 2.5rem;
  }
  @media (max-width: 576px) {
    * {
      font-size: 0.875rem;
    }
  }
  @media (max-width: 558px) {
    * {
      font-size: 0.875rem;
    }
  }
` as typeof DetailedDropdown

type SortProduct = [AllListingsSortByEnum, AllListingsSortOrderEnum]

const prod2Key = (p: SortProduct): string => `${p[0]}&${p[1]}`
const key2Prod = (s: string): SortProduct => s.split('&') as SortProduct

const DEFAULT_SORT_OPTION = {
  label: 'Closing Soonest',
  sortProduct: ['endTime', 'asc'] as SortProduct,
}

const sortOptions: Array<{
  label: string
  sortProduct: SortProduct
}> = [
  DEFAULT_SORT_OPTION,
  { label: 'Newly Listed', sortProduct: ['startTime', 'desc'] },
  { label: 'Bids: Most to Least', sortProduct: ['numberOfBids', 'desc'] },
  { label: 'Bids: Least to Most', sortProduct: ['numberOfBids', 'asc'] },
  { label: 'Price: Lowest First', sortProduct: ['winningBidAmount', 'asc'] },
  { label: 'Price: Highest First', sortProduct: ['winningBidAmount', 'desc'] },
]

const useSortOptions = () => {
  return useMemo(() => {
    return sortOptions.map(({ label, sortProduct }) => ({
      label,
      value: prod2Key(sortProduct),
    }))
  }, [])
}

const SortByDropdown = () => {
  const options = useSortOptions()
  const {
    state: { sortBy, sortOrder },
    setSort,
  } = useAuctionSearch() as {
    state: {
      sortBy: AllListingsSortByEnum | undefined
      sortOrder: AllListingsSortOrderEnum | undefined
    }
    setSort: (
      sortBy: AllListingsSortByEnum,
      sortOrder: AllListingsSortOrderEnum
    ) => void
  }

  const currentSortBy = sortBy ?? DEFAULT_SORT_OPTION.sortProduct[0]
  const currentSortOrder = sortOrder ?? DEFAULT_SORT_OPTION.sortProduct[1]

  const onChange = useCallback(
    ({ value }: { value: string | number }) => {
      const [sortBy, sortOrder] = key2Prod(value as string)
      setSort(sortBy, sortOrder)
    },
    [setSort]
  )

  return (
    <StyledDropdown
      options={options}
      value={prod2Key([currentSortBy, currentSortOrder])}
      onChange={onChange}
      label={'Sort by'}
      dropdownMaxHeight={600}
      labelToLeft
    />
  )
}

export default SortByDropdown
