import { type AllListingsFilters } from '@b-stock/search-api-client'

import {
  desimplifyRegionFilter,
  formatConditionValues,
  simplifyRegionFilter,
  type RegionItems,
} from '@components/auctionFilters/shared/filterUtils'
import type {
  SearchState,
  AllListingsSortByEnum,
} from '@components/BaseSearchProvider/types'
import {
  validateSortBy,
  validateSortOrder,
} from '@components/BaseSearchProvider/utils'
import type { SearchParams } from '@queries/listingSearchQuery'

import defaultState from './defaultState'
import type { AuctionFiltersState } from './types'
import type { Range } from '../auctionFilters/shared/NumericRangeFilter'

const PAGE_SIZE = 20

type AuctionSearchSortBy = AllListingsSortByEnum

// imported from buyer-portal src/components/layouts/UserPageTemplate/DropdownFilter/filtertype/DateRange/DateRange.tsx
type DateRangeType = [null | string, null | string]

// START - imported from buyer-portal src/components/layouts/UserPageTemplate/utils.ts

export const isRange = (filterState: unknown): filterState is Range =>
  Array.isArray(filterState) &&
  filterState.length === 2 &&
  (filterState[0] === null || typeof filterState[0] === 'number') &&
  (filterState[1] === null || typeof filterState[1] === 'number')

export const isRangeList = (filterState: unknown): filterState is Range[] =>
  Array.isArray(filterState) && filterState.every((o) => isRange(o))

export const isDateRange = (
  filterState: unknown
): filterState is DateRangeType =>
  Array.isArray(filterState) &&
  filterState.length === 2 &&
  (filterState[0] === null || typeof filterState[0] === 'string') &&
  (filterState[1] === null || typeof filterState[1] === 'string')

export const isStringList = (filterState: unknown): filterState is string[] =>
  Array.isArray(filterState) && filterState.every((x) => typeof x === 'string')

export const isBoolean = (filterState: unknown): filterState is boolean =>
  typeof filterState === 'boolean'

// END - imported from buyer-portal src/components/layouts/UserPageTemplate/utils.ts

const SUPPORTED_QUERY_PARAMS = ['q', 'p', 's', 'o'] as const
type SupportedQueryParam = (typeof SUPPORTED_QUERY_PARAMS)[number]

const composeAuctionStateFromQueryParams = (
  queryParams: {
    [key: string]: string | string[] | undefined
  },
  allFilters?: AllListingsFilters
): SearchState<AuctionFiltersState> => {
  const { q, p, s, o } = queryParams

  const filters = Object.entries(queryParams).reduce(
    (acc, [key, value]) => {
      if (
        !SUPPORTED_QUERY_PARAMS.includes(key as SupportedQueryParam) &&
        key in defaultState.filters
      ) {
        const filterKey = key as keyof AuctionFiltersState

        if (value && typeof value === 'string') {
          try {
            acc[filterKey] =
              key === 'region'
                ? desimplifyRegionFilter(
                    JSON.parse(value),
                    (allFilters?.region || []) as RegionItems[]
                  )
                : // TODO: If data changes in the future to only send "New", this can be reverted
                  key === 'condition'
                  ? formatConditionValues(
                      JSON.parse(value),
                      allFilters?.condition || []
                    )
                  : JSON.parse(value)
          } catch (e) {
            console.warn(`Failed to parse filter value for ${key}:`, e)
          }
        }
      }

      return acc
    },
    { ...defaultState.filters }
  )

  return {
    query: typeof q === 'string' ? q : null,
    page: typeof p === 'string' ? parseInt(p) : 1,
    sortBy: validateSortBy(s as AuctionSearchSortBy),
    sortOrder: validateSortOrder(o),
    filters: filters as AuctionFiltersState,
  }
}

type AuctionFilterParams = {
  category?: string[]
  condition?: string[]
  shipmentType?: string[]
  listingType?: string[]
  inventoryType?: string[]
  buyingFormat?: string[]
  country?: string[]
  usState?: string[]
  minBidValue?: number
  maxBidValue?: number
  minUnits?: number
  maxUnits?: number
  sellers?: string[]
  minRetailPrice?: number
  maxRetailPrice?: number
  [key: string]: string[] | string | number | null | undefined
}

const composeQueryFromAuctionState = ({
  page,
  query,
  sortBy,
  sortOrder,
  filters,
  allFilters,
}: SearchState<AuctionFiltersState>): SearchParams['params'] => {
  const offset = (page - 1) * PAGE_SIZE

  const formattedFilters: AuctionFilterParams = {}
  Object.entries(filters).forEach((filter) => {
    const [key, value] = filter
    const castedKey = key as keyof AuctionFilterParams

    if (isStringList(value) && value.length > 0) {
      if (castedKey === 'region') {
        formattedFilters[castedKey] = desimplifyRegionFilter(
          value,
          (allFilters?.region || []) as RegionItems[]
        )
        return
      }
      ;(formattedFilters[castedKey] as unknown) = value
      return
    }

    if (
      (isDateRange(value) && value[0] && value[1]) ||
      (value &&
        isRange(value) &&
        value[0] !== undefined &&
        value[1] !== undefined)
    ) {
      const filterKeyMap: { [key: string]: [string, string] } = {
        currentBidRanges: ['minBidValue', 'maxBidValue'],
      }
      const mappedKey = filterKeyMap[castedKey]
      if (mappedKey) {
        const [lowerKey, upperKey] = mappedKey
        formattedFilters[lowerKey] = Number(value[0])
        formattedFilters[upperKey] =
          value[1] === null ? value[1] : Number(value[1])

        return
      }
    }
  })

  if (formattedFilters.minUnits === 0) {
    delete formattedFilters.minUnits
  }

  if (formattedFilters.minRetailPrice === 0) {
    delete formattedFilters.minRetailPrice
  }

  if (formattedFilters.minBidValue === 0) {
    delete formattedFilters.minBidValue
  }

  return {
    ...formattedFilters,
    query,
    limit: PAGE_SIZE,
    offset,
    sortBy: sortBy,
    sortOrder,
  }
}

export type UrlParams = {
  [key: string]: string
}

const composeUrlParamsFromAuctionState = (
  state: SearchState<AuctionFiltersState>,
  excludeFilters?: string[],
  allFilters?: AllListingsFilters
): UrlParams => {
  const urlParams: UrlParams = {}

  if (state.sortOrder) {
    urlParams.o = state.sortOrder
  }

  if (state.sortBy) {
    urlParams.s = state.sortBy
  }

  if (state.query) {
    urlParams.q = state.query
  }

  Object.entries(state.filters || {}).forEach((filter) => {
    const [key, value] = filter

    if ((excludeFilters ?? []).includes(key)) {
      return
    }

    if (isStringList(value) && value.length > 0) {
      urlParams[key] = JSON.stringify(
        key === 'region'
          ? simplifyRegionFilter(
              value,
              (allFilters?.region || []) as RegionItems[]
            )
          : // TODO: If data changes in the future to only send "New", this can be reverted
            key === 'condition'
            ? formatConditionValues(value, allFilters?.condition || [])
            : value
      )
    } else if (
      (isDateRange(value) && value[0] && value[1]) ||
      (value && isRange(value) && (value[0] || value[1]))
    ) {
      urlParams[key] = JSON.stringify(value)
    }
  })

  return urlParams
}

export {
  composeAuctionStateFromQueryParams,
  composeQueryFromAuctionState,
  composeUrlParamsFromAuctionState,
  PAGE_SIZE,
}
