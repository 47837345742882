import { useIntl } from 'react-intl'

import { ListingEnums } from '@b-stock/bstock-react/locale'
import type { ItemDtoItemConditionEnum } from '@b-stock/listing-api-client'

import CategoricalFilter from '../../shared/CategoricalFilter'
import {
  useFilterOptions,
  createCategoricalAppliedComponent,
} from '../../shared/filterUtils'
import type { AuctionFilter } from '../../types'

const toDisplayName = (option: string) => {
  if (option === 'Brand New') {
    return <>New</>
  }

  return (
    <ListingEnums.ItemCondition.Component
      // We know the option will be an enum value in practice, even though
      // the API doesn't strictly enforce it. If it ever isn't, ListingEnums
      // should gracefully handle this and just output the raw option string.
      value={option as ItemDtoItemConditionEnum}
    />
  )
}

const ConditionFilter: AuctionFilter<Set<string>> = ({
  onChange,
  desktopScreen,
  items: itemsData,
  availableItems: availableItemsData,
  value,
}) => {
  const intl = useIntl()
  const items = useFilterOptions(itemsData, toDisplayName)
  const availableItems = useFilterOptions(availableItemsData, toDisplayName)

  return (
    <CategoricalFilter
      items={items}
      availableItems={availableItems}
      selected={value}
      name={intl.formatMessage({
        id: 'Filters.condition',
      })}
      onChange={onChange}
      desktopScreen={desktopScreen}
    />
  )
}

ConditionFilter.getOwnProps = ({ state, data, updateFilters }) => {
  return {
    filterKey: 'condition',
    items: data?.allFilters?.condition || [],
    value: new Set(state.filters.condition),
    onChange: (value: Set<string>) => updateFilters({ condition: [...value] }),
  }
}

ConditionFilter.label = 'Filters.condition'

ConditionFilter.Applied = createCategoricalAppliedComponent(
  'condition',
  ConditionFilter.label,
  toDisplayName
)

export default ConditionFilter
