import type { ReactNode } from 'react'

import { styled } from 'styled-components'
import type { KnownTarget } from 'styled-components/dist/types'

import { Button, FormattedMessage } from '@b-stock/bstock-react'
import { Breakpoints } from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

import FilterCheckbox from '@components/auctionFilters/shared/FilterCheckbox'

type OnlyableCheckboxProps = {
  as?: KnownTarget
  displayName: ReactNode
  checked: boolean | 'PARTIAL'
  onChange: () => void
  onOnly: () => void
  disabled?: boolean
  onFilterClick?: () => void
}

const StyledOnlyButton = styled(Button)`
  margin-left: 4px;
  && {
    height: 22px;
    min-width: 40px;
  }
  position: absolute;
  right: 1rem;
  background: ${designColors.primary.hoverOverlay};

  @media ${Breakpoints.min.medium} {
    right: 0;
  }
`

const Container = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding: 0 0 0.25rem 0;

  &:hover {
    background: ${designColors.primary.hoverOverlay};
    color: ${designColors.primary.default};
  }

  ${StyledOnlyButton} {
    visibility: hidden;
  }

  &:hover ${StyledOnlyButton} {
    visibility: visible;
  }
`

const OnlyableCheckbox = ({
  as,
  displayName,
  checked,
  onChange,
  onOnly,
  disabled,
  onFilterClick,
}: OnlyableCheckboxProps) => {
  const handleOnOnlyClick = () => {
    onOnly()
    if (onFilterClick !== undefined) {
      onFilterClick()
    }
  }

  return (
    <Container as={as}>
      <FilterCheckbox
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        label={displayName}
        onClick={onFilterClick}
      />
      <StyledOnlyButton
        size="small"
        onClick={handleOnOnlyClick}
        appearance="secondary"
      >
        <FormattedMessage id="Filters.checkbox.selectOnly" />
      </StyledOnlyButton>
    </Container>
  )
}

export default OnlyableCheckbox
