import {
  AllListingsSortByEnum,
  AllListingsSortOrderEnum,
} from '@b-stock/search-api-client'

import type { SearchState } from '@components/BaseSearchProvider/types'

import type { AuctionFiltersState } from './types'

const defaultState: SearchState<AuctionFiltersState> = {
  query: null,
  page: 1,
  sortBy: AllListingsSortByEnum.EndTime,
  sortOrder: AllListingsSortOrderEnum.Asc,
  filters: {
    categories: [],
    storefront: [],
    condition: [],
    inventoryType: [], // New property
    listingType: [],
    buyingFormat: [],
    currentBidRanges: [null, null],
    shipmentType: [],
    productType: [],
    region: [],
    warehouseLocation: [],
    distance: null,
  },
}

export default defaultState
