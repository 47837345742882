import type React from 'react'
import { useState, useCallback } from 'react'

import Slider from 'rc-slider'
import { useIntl } from 'react-intl'
import { NumericFormat } from 'react-number-format'
import { styled, css } from 'styled-components'
import 'rc-slider/assets/index.css'

import { Button } from '@b-stock/bstock-react'
import {
  Colors,
  Shadows,
  Typography,
} from '@b-stock/bstock-react/design-system'

import type { AuctionFilter } from '../../types'

type ContainerProps = {
  $disabled?: boolean
  $focus?: boolean
  $error?: string
}

type DistanceInputProps = {
  disabled?: boolean
  className?: string
  value: number | null
  readOnly?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  label: React.ReactNode
  type?: string
  error?: string
  suffix?: React.ReactNode
  minValue: number
  maxValue: number
  step?: number
}

const RangeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`

const StyledRange = styled(Slider)`
  height: 1.25rem;
  padding: 1rem 0;

  .rc-slider-rail {
    background: ${Colors.Semantic.Neutral.medium_grey};
    height: 0.25rem;
    margin-bottom: -0.25rem;
    border-radius: 6.25rem;
  }

  .rc-slider-track {
    background: ${Colors.Semantic.BStock.onhover};
    height: 0.25rem;
    margin-bottom: -0.25rem;
    border-radius: 6.25rem;
  }

  .rc-slider-handle {
    background: ${Colors.Semantic.Neutral.white};
    border: 0.0625rem solid ${Colors.Semantic.BStock.default.lightBackground};
    ${Shadows.Popovers}
    height: 1.25rem;
    width: 1.25rem;
    opacity: 1;
    margin-top: -0.5rem;
  }

  &&& {
    .rc-slider-handle-dragging {
      background: ${Colors.Semantic.BStock.onclick.lightBackground};
      border-color: ${Colors.Semantic.BStock.onclick.lightBackground};
      ${Shadows.Popovers}
    }
  }
`

const MinMaxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
`

const MinMaxLabel = styled(NumericFormat)`
  ${Typography.Body4}
  color: ${Colors.Semantic.Neutral.black};
`

const ZipeCodeInput = styled.input`
  font-size: 1rem;
  padding: 12px 12px;
  border: 1px solid ${Colors.Semantic.Neutral.medium_grey};
  border-radius: 3px;
  width: 100%;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`

const StyledContainer = styled.label<ContainerProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  border: 0.0625rem solid ${Colors.Semantic.Neutral.medium_grey};
  background-color: ${Colors.Semantic.Neutral.white};
  color: ${Colors.Semantic.Neutral.dark_grey};
  border-radius: 0.25rem;
  padding: 1.5rem 0.375rem 0.375rem;

  ${({ $disabled }) =>
    $disabled &&
    `
    background-color: ${Colors.Semantic.Neutral.light_grey};
    color: ${Colors.Semantic.BStock.disabled};
  `}

  ${({ $focus }) =>
    $focus &&
    `    border-color: ${Colors.Semantic.BStock.onhover};
  `}

  ${({ $error }) =>
    $error
      ? `
    border-color: ${getErrorTypeColor($error)};
  `
      : ''}
`

// Helper function to get error color
const getErrorTypeColor = (_errorType?: string): string => {
  return Colors.Semantic.Error.highlight
}

const StyledLabelText = styled.span<{
  $shrinkLabel: boolean
}>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  pointer-events: none;
  height: 3.5rem;
  position: absolute;
  left: 0.375rem;
  right: 0.9375rem;
  transition-property: top, font-size, line-height;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  ${({ $shrinkLabel }) =>
    $shrinkLabel
      ? css`
          ${Typography.Body4};
          top: 0.375rem;
          left: 0.375rem;
        `
      : css`
          ${Typography.Body2};
          top: 0.375rem;
        `}
`

const StyledInput = styled.input`
  font-family: inherit;
  ${Typography.Body2}
  color: ${Colors.Semantic.Neutral.black};
  border: none;
  outline: none;
  background: none;
  padding: 0;
  &:disabled {
    color: ${Colors.Semantic.Neutral.dark_grey};
  }
  &:focus {
    border: none;
    outline: none;
    background: none;
    flex: 1;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  -moz-appearance: textfield;
  margin: 0;
`

const InputContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`

const StyledButton = styled(Button)`
  margin-top: 0.5rem;
  width: -webkit-fill-available;
  width: -moz-fill-available;
`

/**
 * Input component for displaying and editing mile values
 */
const DistanceInput = ({
  label,
  value,
  disabled,
  error,
  className,
  onFocus,
  onBlur,
  suffix,
  minValue,
  maxValue,
  step,
  ...props
}: DistanceInputProps) => {
  const [isFocused, setFocused] = useState(false)

  const handleFocus = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true)
      onFocus?.(event)
    },
    [onFocus]
  )

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(false)
      onBlur?.(event)
    },
    [onBlur]
  )

  return (
    <StyledContainer
      className={className}
      $disabled={disabled}
      $error={error}
      $focus={isFocused}
    >
      <StyledLabelText $shrinkLabel={isFocused || !!value}>
        {label}
      </StyledLabelText>

      <InputContainer>
        <StyledInput
          value={value ?? ''}
          disabled={disabled}
          {...props}
          onFocus={handleFocus}
          onBlur={handleBlur}
          min={minValue}
          max={maxValue}
          step={step}
        />

        {(!value || (value !== null && value >= maxValue)) &&
        suffix &&
        !isFocused ? (
          <div>{suffix}</div>
        ) : null}
      </InputContainer>
    </StyledContainer>
  )
}

/**
 * Filter component that allows users to specify a distance range with a slider and input field
 */

const minDistance = 1
const maxDistance = 2000

const DistanceRangeFilter: AuctionFilter<number | null> = () => {
  const intl = useIntl()
  const [distance, setDistance] = useState<number | null>(maxDistance)
  const [zipCode, setZipCode] = useState<string | null>(null)
  const [showResults, setShowResults] = useState(false)

  const handleSliderChange = useCallback(
    (value: number | number[]) => {
      const singleValue = Array.isArray(value) ? value[0] : value
      setDistance(singleValue)
      if (zipCode && singleValue < 2000) {
        setShowResults(true)
      } else {
        setShowResults(false)
      }
    },
    [zipCode]
  )

  const handleInputChange = useCallback(
    (value: string) => {
      if (value === '') {
        setDistance(null)
        return
      }

      const parsedValue = parseInt(value)
      if (isNaN(parsedValue)) {
        return
      }

      const validValue = Math.abs(parsedValue)
      setDistance(validValue)

      if (zipCode && validValue < 2000) {
        setShowResults(true)
      } else {
        setShowResults(false)
      }
    },
    [zipCode]
  )

  const handleBlur = useCallback(() => {
    if (!distance || distance > maxDistance || distance < minDistance) {
      setDistance(maxDistance)
      setShowResults(false)
    }
  }, [distance])

  const handleZipCodeChange = useCallback(
    (value: string) => {
      if (value === '') {
        setZipCode(null)
        setShowResults(false)
        return
      }
      setZipCode(value)
      if (distance && distance < maxDistance) {
        setShowResults(true)
      }
    },
    [distance]
  )

  return (
    <>
      <ZipeCodeInput
        placeholder={intl.formatMessage({
          id: 'Filters.rangeDistance.zipPlaceholder',
        })}
        onChange={(e) => handleZipCodeChange(e.target.value)}
        value={zipCode || ''}
        type="number"
      />
      <RangeContainer>
        <StyledRange
          min={minDistance}
          max={maxDistance}
          step={1}
          value={distance ?? 0}
          onChange={handleSliderChange}
        />
      </RangeContainer>
      <MinMaxContainer>
        <MinMaxLabel displayType="text" thousandSeparator value={1} />
        <MinMaxLabel
          displayType="text"
          thousandSeparator
          suffix={` ${intl.formatMessage({ id: 'Filters.rangeDistance.unit' })}`}
          value={2000}
        />
      </MinMaxContainer>
      <DistanceInput
        label={intl.formatMessage({ id: 'Filters.rangeDistance.label' })}
        value={distance}
        onChange={(e) => handleInputChange(e.target.value)}
        onBlur={handleBlur}
        suffix="+"
        className="distance-input"
        type="number"
        readOnly={false}
        minValue={minDistance}
        maxValue={maxDistance}
        step={1}
      />
      {showResults && (
        <StyledButton
          appearance="primary"
          size="medium"
          onClick={() => handleSliderChange(distance ?? 0)}
        >
          {intl.formatMessage({ id: 'Filters.rangeDistance.filterButton' })}
        </StyledButton>
      )}
    </>
  )
}

DistanceRangeFilter.getOwnProps = ({ state, updateFilters }) => ({
  filterKey: 'distance',
  onChange: (distance: number | null) => {
    updateFilters({ distance })
  },
  value: state.filters.distance || null,
})

DistanceRangeFilter.label = 'Filters.distance'

export default DistanceRangeFilter
