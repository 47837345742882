import { useIntl } from 'react-intl'

import { ListingEnums } from '@b-stock/bstock-react/locale'
import type { ItemCategoriesDtoCategoriesEnum } from '@b-stock/listing-api-client'

import CategoricalFilter from '../../shared/CategoricalFilter'
import {
  useFilterOptions,
  createCategoricalAppliedComponent,
} from '../../shared/filterUtils'
import type { AuctionFilter } from '../../types'

const categoryDisplayNames: {
  [key in string]?: string
} = {
  'Apparel Shoes & Accessories': 'Apparel, Shoes & Accessories',
  'Books Movies & Music': 'Books, Movies & Music',
  'Toys Kids & Baby': 'Toys, Kids & Baby',
}

const toDisplayName = (option: string) => {
  if (categoryDisplayNames[option]) {
    return <span>{categoryDisplayNames[option]}</span>
  }

  return (
    <ListingEnums.Category.Component
      // We know the option will be an enum value in practice, even though
      // the API doesn't strictly enforce it. If it ever isn't, ListingEnums
      // should gracefully handle this and just output the raw option string.
      value={option as ItemCategoriesDtoCategoriesEnum}
    />
  )
}

const CategoryFilter: AuctionFilter<Set<string>> = ({
  onChange,
  desktopScreen,
  items: itemsData,
  availableItems: availableItemsData,
  value,
}) => {
  const intl = useIntl()
  const items = useFilterOptions(itemsData, toDisplayName)
  const availableItems = useFilterOptions(availableItemsData, toDisplayName)

  return (
    <CategoricalFilter
      alwaysUseModal
      items={items}
      availableItems={availableItems}
      name={intl.formatMessage({
        id: 'Filters.category',
      })}
      selected={value}
      onChange={onChange}
      desktopScreen={desktopScreen}
    />
  )
}

CategoryFilter.getOwnProps = ({ state, data, updateFilters }) => {
  return {
    filterKey: 'categories',
    items: data?.allFilters?.categories || [],
    value: new Set(state.filters.categories),
    onChange: (value: Set<string>) => updateFilters({ categories: [...value] }),
  }
}

CategoryFilter.label = 'Filters.category'

CategoryFilter.Applied = createCategoricalAppliedComponent(
  'categories',
  CategoryFilter.label,
  toDisplayName
)

export default CategoryFilter
