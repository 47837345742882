'use client'

import {
  faHourglassHalf,
  faHourglassStart,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Countdown } from '@b-stock/bstock-next'
import { Colors } from '@b-stock/bstock-react/design-system'

import { isLessThanOneHour } from '@helpers/dateUtils'

import { IconP } from '../ui'

const ListingTimeLeft = ({ endTime }: { endTime: Date }) => {
  const color = isLessThanOneHour(endTime)
    ? Colors.Semantic.Warning.highlight
    : Colors.Semantic.Neutral.very_dark_grey
  const hourGlassIcon = isLessThanOneHour(endTime)
    ? faHourglassHalf
    : faHourglassStart

  return (
    <>
      <IconP $color={color} data-testid="icon">
        <FontAwesomeIcon icon={hourGlassIcon} size="sm" />
        <Countdown target={endTime} />
      </IconP>
    </>
  )
}

export default ListingTimeLeft
