'use client'

import { useEffect, useRef } from 'react'

import type { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { faLocationDot, faRecycle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/image'

import { ddLogger } from '@b-stock/bstock-react'
import { Colors } from '@b-stock/bstock-react/design-system'
import { ListingEnums } from '@b-stock/bstock-react/locale'
import { ItemConditionDtoItemConditionsEnum } from '@b-stock/listing-api-client'

import ComponentErrorBoundary from '@helpers/ComponentErrorBoundary'
import { parseCustomDate } from '@helpers/dateUtils'
import { useAnalytics } from '@helpers/telemetry/SegmentAnalytics'
import type { SearchResult } from '@queries/listingSearchQuery'

import BidDetails from './bidDetails/BidDetails'
import ListingTag from './listingTag/ListingTag'
import { getLogoOverride } from './marketplaceLogoConfig'
import {
  Address,
  Card,
  Condition,
  Content,
  IconP,
  ImageOverlay,
  ImageSection,
  SiteDetails,
  SiteLogo,
  Tags,
  Title,
} from './ui'

interface ListingCardProps {
  listing: SearchResult
}

const ConditionTag = ({
  color,
  icon,
  text,
  additionalConditions,
}: {
  color: string
  icon?: IconDefinition
  text: React.ReactNode
  additionalConditions: number
}) => (
  <Condition $color={color}>
    <div>
      {icon && <FontAwesomeIcon icon={icon} size="sm" />}
      <p>{text}</p>
      {additionalConditions > 0 && (
        <span className="more-salvage">+ {additionalConditions} more</span>
      )}
    </div>
  </Condition>
)

const ListingCard = ({ listing }: ListingCardProps) => {
  const { trackButtonClicked } = useAnalytics()
  const hasLogged = useRef(false)

  //For Amazon US and UK auctions ONLY
  const logoUrl = getLogoOverride(listing.siteAbb) ?? listing.siteLogoUrl

  const showCard =
    !!listing.title &&
    !!listing.auctionUrl &&
    !!listing.auctionId &&
    !!listing.endTime
  const showCondition = !!listing.condition
  let listingConditions: string[] = []
  let isSalvage = false

  useEffect(() => {
    if (!hasLogged.current && !showCard) {
      ddLogger.warn(
        'Home Portal ListingCard Listing data missing either title, auctionUrl, auctionId, or endTime'
      )
      hasLogged.current = true
    }
  }, [showCard])

  if (!showCard) {
    return null
  }

  if (showCondition) {
    listingConditions = listing.condition
      .split(',')
      .map((condition) => condition.trim())

    isSalvage = listingConditions.includes(
      ItemConditionDtoItemConditionsEnum.Salvage
    )
  }

  const endTime = parseCustomDate(listing.endTime)

  const handleClickListingCard = () => {
    const is3mp = listing.siteAbb.toLowerCase() === 'tmp'

    trackButtonClicked(
      'all_auction', // screen_name
      'all_auction_listing', // button_name
      'home_portal', // source
      'buyer', // entity_type
      {
        auction_id: listing.auctionId,
        cta_url: listing.auctionUrl,
        listing_source: is3mp ? '3MP' : 'magento',
        referrer: document.referrer,
        seller_name_temp: is3mp ? '3MP' : listing.storefrontName,
        seller_name_ua: listing.storefrontName,
        url: document.URL,
      }
    )
  }

  return (
    <ComponentErrorBoundary>
      <Card
        href={listing.auctionUrl}
        target="_blank"
        rel="noopener"
        onClick={handleClickListingCard}
      >
        <ImageSection>
          <ImageOverlay>
            <Tags>
              <ListingTag listing={listing} />
            </Tags>
            {!!listing.storefrontName && (
              <SiteDetails data-testid="site-details">
                <SiteLogo>
                  {logoUrl ? (
                    <Image
                      src={logoUrl}
                      alt={`${listing.storefrontName} logo`}
                      fill
                    />
                  ) : (
                    <div data-testid="fallback-logo">
                      {listing.storefrontName.charAt(0)}
                    </div>
                  )}
                </SiteLogo>
                <b>{listing.storefrontName}</b>
              </SiteDetails>
            )}
          </ImageOverlay>
          <Image
            src={
              listing.primaryImageUrl ||
              '/home-portal/images/fallbackListingImage.png'
            }
            alt={`Image of ${listing.title}`}
            width={236}
            height={168}
          />
        </ImageSection>
        <Content>
          <Title>{listing.title}</Title>
          {!!listing.region && !!listing.sellerZipCode && (
            <IconP
              $color={Colors.Semantic.Neutral.very_dark_grey}
              data-testid="region-details"
            >
              <FontAwesomeIcon icon={faLocationDot} size="sm" />
              <Address>
                {listing.region} &mdash; {listing.sellerZipCode}
              </Address>
            </IconP>
          )}
          {showCondition && (
            <>
              {isSalvage ? (
                <ConditionTag
                  color={Colors.Semantic.Warning.tag}
                  icon={faRecycle}
                  text={
                    <ListingEnums.ItemCondition.Component
                      value={ItemConditionDtoItemConditionsEnum.Salvage}
                    />
                  }
                  additionalConditions={listingConditions.length - 1}
                  data-testid="condition-details"
                />
              ) : (
                <ConditionTag
                  color={Colors.Semantic.Neutral.light_grey}
                  text={
                    // TODO: If data changes in the future to only send "New", this can be reverted
                    listingConditions[0] === 'Brand New'
                      ? 'New'
                      : listingConditions[0]
                  }
                  additionalConditions={listingConditions.length - 1}
                  data-testid="condition-details"
                />
              )}
            </>
          )}

          <BidDetails listing={listing} endTime={endTime} />
        </Content>
      </Card>
    </ComponentErrorBoundary>
  )
}

export default ListingCard
