import { styled } from 'styled-components'

import { Modal } from '@b-stock/bstock-react'
import { Colors, Typography } from '@b-stock/bstock-react/design-system'

export const Header = styled(Modal.Header)`
  text-transform: capitalize;
`

export const ModalStatistic = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 1rem 3rem 0;

  &::after {
    content: '';
    display: block;
    margin-top: 0.75rem;
    border-bottom: 0.0625rem solid ${Colors.Semantic.Neutral.medium_grey};
  }
`

export const TotalSelected = styled.p`
  ${Typography.Subtitle2};
  color: ${Colors.Semantic.Neutral.black};
`
