import { styled } from 'styled-components'

import { Typography, Colors } from '@b-stock/bstock-react/design-system'

export const BidDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

export const Bid = styled.p`
  ${Typography.Title3}
  margin: 0;
  color: ${Colors.Semantic.Neutral.light_black};
  display: flex;
  gap: 0.25rem;
  align-items: baseline;

  span {
    ${Typography.Body3}
    color: ${Colors.Semantic.Neutral.light_black};
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

export const HiddenPrice = styled.p`
  ${Typography.Title3}
  color: ${Colors.Primitive.GreyScale.grey_2};
  margin: 0;
  display: flex;
  gap: 0.25rem;
  align-items: baseline;

  span {
    ${Typography.Body3}
    color: ${Colors.Primitive.GreyScale.grey_2};
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

export const Msrp = styled.p`
  ${Typography.Body3}
  display: flex;
  align-items: center;
  margin: 0;
  color: ${Colors.Semantic.Neutral.dark_grey};
  gap: 0.25rem;
`

export const BidAmountText = styled.p`
  ${Typography.Body3}
  color: ${Colors.Semantic.BStock.disabled};
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`

export const WinningBidAmount = styled(BidAmountText)`
  color: ${Colors.Semantic.Success.copy};
`

export const LosingBidAmount = styled(BidAmountText)`
  color: ${Colors.Semantic.Error.highlight};
`

export const ClickToSeePriceText = styled.p`
  ${Typography.Title3}
  color: ${Colors.Semantic.Neutral.light_black}
`
