import { useEffect, useState } from 'react'

import pluralize from 'pluralize'
import { styled } from 'styled-components'

import { FormattedMessage, Modal } from '@b-stock/bstock-react'
import { Breakpoints } from '@b-stock/bstock-react/design-system'

import {
  Header,
  ModalStatistic,
  TotalSelected,
} from '@components/auctionFilters/shared/Modal'
import OnlyableCheckbox from '@components/auctionFilters/shared/OnlyableCheckbox'
import SeeMoreButton from '@components/auctionFilters/shared/SeeMoreButton'
import { ModalFooter, ModalContent } from '@components/common/modal/ui'
import { useAnalytics } from '@helpers/telemetry/SegmentAnalytics'

type RecordType = {
  displayName: React.ReactNode
  key: string
}

type RecordTypeWithDisable = {
  displayName: React.ReactNode
  key: string
  disabled: boolean
}

type CategoricalFilterProps = {
  items: RecordType[]
  availableItems: RecordType[]
  alwaysUseModal?: boolean
  selected: Set<string>
  onChange: (value: Set<string>) => void
  name?: string
  desktopScreen?: boolean
  customModal?: (param: () => void) => React.ReactNode
}

const List = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  label > span {
    text-transform: capitalize;
  }
`

type ModalList = {
  $items: number
}

const ModalList = styled(List)<ModalList>`
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 3rem;

  li {
    position: relative;
  }

  @media ${Breakpoints.min.medium} {
    max-height: ${({ $items }) => Math.ceil($items / 2) * 1.75}rem;

    li {
      min-width: 17.4rem;
    }
  }
`

export const splitItems = (
  items: Array<RecordType>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  availableItems: Array<RecordType>,
  alwaysUseModal?: boolean
): {
  itemsCount: number
  displayMore: Array<RecordTypeWithDisable>
  displayItems: Array<RecordTypeWithDisable>
  displayModal: boolean
} => {
  const itemsCount = items.length
  let displayMore: Array<RecordTypeWithDisable> = []
  let displayItems: Array<RecordTypeWithDisable> = []

  //TODO: handle showing available filters

  const displayModal = alwaysUseModal || itemsCount > 16
  if (itemsCount <= 8) {
    displayItems = items
      .slice(0, 5)
      .map((item) => ({ ...item, disabled: false }))
    displayMore = items
      .slice(5, 8)
      .map((item) => ({ ...item, disabled: false }))
  } else if (itemsCount > 8 && itemsCount <= 16) {
    displayItems = items
      .slice(0, 5)
      .map((item) => ({ ...item, disabled: false }))
    displayMore = items
      .slice(5, 16)
      .map((item) => ({ ...item, disabled: false }))
  } else if (itemsCount > 16) {
    displayItems = items
      .slice(0, 5)
      .map((item) => ({ ...item, disabled: false }))
  }

  return {
    itemsCount,
    displayMore,
    displayItems,
    displayModal,
  }
}

interface DesktopCategoricalFilterProps
  extends Omit<CategoricalFilterProps, 'onChange'> {
  alwaysUseModal?: boolean
  handleOnlyClick: (key: string) => void
  handleModalOnlyClick: (key: string) => void
  filterName: string
  handleModalChange: (key: string) => void
  handleChange: (key: string) => void
  resetFilters: () => void
  submitModal: () => void
  modalSelected: Set<string>
}

interface MobileCategoricalFilterProps
  extends Omit<CategoricalFilterProps, 'onChange'> {
  handleOnlyClick: (key: string) => void
  handleChange: (key: string) => void
  name: string
}

const DesktopCategoricalFilter = ({
  items,
  availableItems,
  alwaysUseModal,
  selected,
  modalSelected,
  customModal,
  handleOnlyClick,
  handleModalOnlyClick,
  handleChange,
  handleModalChange,
  filterName,
  submitModal,
  resetFilters,
}: DesktopCategoricalFilterProps) => {
  const [showMore, setShowMore] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const { displayMore, displayItems, displayModal } = splitItems(
    items,
    availableItems,
    alwaysUseModal
  )
  const { trackButtonClicked } = useAnalytics()

  const handleShowMore = () => {
    if (!showMore) {
      setShowModal(true)
    }
    setShowMore((showMore) => !showMore)
  }

  const cancelShowMore = () => {
    resetFilters()
    setShowMore(false)
  }

  const handleModalClose = () => {
    resetFilters()
    setShowMore(false)
  }

  const handleAuctionsFilterClick = (title: string, name: string) => {
    trackButtonClicked(
      'all_auction', // screen_name
      'all_auction_filters', // button_name
      'home_portal', // source
      'buyer', // entity_type
      {
        device: 'Desktop',
        filter_title: title,
        filter_name: name,
      }
    )
  }

  return (
    <>
      <List>
        {displayItems.map(({ displayName, key }) => (
          <OnlyableCheckbox
            key={key}
            displayName={displayName}
            checked={selected.has(key)}
            onChange={() => handleChange(key)}
            onOnly={() => handleOnlyClick(key)}
            //disabled={disabled}
            onFilterClick={() => handleAuctionsFilterClick(filterName, key)}
          />
        ))}

        {showMore &&
          displayMore.map(({ displayName, key }) => (
            <OnlyableCheckbox
              key={key}
              displayName={displayName}
              checked={selected.has(key)}
              onChange={() => handleChange(key)}
              onOnly={() => handleOnlyClick(key)}
              onFilterClick={() => handleAuctionsFilterClick(filterName, key)}
            />
          ))}
        {items.length > 5 && (
          <SeeMoreButton showMore={showMore} handleShowMore={handleShowMore} />
        )}
      </List>
      {displayModal &&
        showMore &&
        showModal &&
        (customModal ? (
          customModal(handleModalClose)
        ) : (
          <Modal closeModal={handleModalClose}>
            <Header>{filterName}</Header>
            <ModalContent>
              <ModalList $items={items.length}>
                {items.map(({ displayName, key }) => (
                  <OnlyableCheckbox
                    key={key}
                    displayName={displayName}
                    checked={modalSelected.has(key)}
                    onChange={() => handleModalChange(key)}
                    onOnly={() => handleModalOnlyClick(key)}
                    onFilterClick={() =>
                      handleAuctionsFilterClick(filterName, key)
                    }
                  />
                ))}
              </ModalList>
            </ModalContent>
            <ModalFooter>
              <ModalStatistic>
                <TotalSelected>
                  <FormattedMessage
                    id={'Filters.modal.list'}
                    values={{
                      amount: modalSelected.size,
                      name: pluralize(filterName, modalSelected.size),
                    }}
                  />
                </TotalSelected>
              </ModalStatistic>
              <Modal.Actions
                alignment="end"
                submitMessage={<FormattedMessage id={'Common.showResult'} />}
                cancelMessage={<FormattedMessage id={'Common.cancel'} />}
                onCancel={cancelShowMore}
                onSubmit={() => {
                  submitModal()
                  cancelShowMore()
                }}
              />
            </ModalFooter>
          </Modal>
        ))}
    </>
  )
}

const MobileCategoricalFilter = ({
  items,
  name,
  selected,
  handleChange,
  handleOnlyClick,
}: MobileCategoricalFilterProps) => {
  const { trackButtonClicked } = useAnalytics()

  const handleAuctionsFilterClick = (title: string, name: string) => {
    trackButtonClicked(
      'all_auction', // screen_name
      'all_auction_filters', // button_name
      'home_portal', // source
      'buyer', // entity_type
      {
        alphabetical_filter: false,
        device: 'Mobile',
        filter_title: title,
        filter_name: name,
      }
    )
  }

  return (
    <ModalList $items={items.length}>
      {items.map(({ displayName, key }) => (
        <OnlyableCheckbox
          key={key}
          displayName={displayName}
          checked={selected.has(key)}
          onChange={() => handleChange(key)}
          onOnly={() => handleOnlyClick(key)}
          onFilterClick={() => handleAuctionsFilterClick(name, key)}
        />
      ))}
    </ModalList>
  )
}

const CategoricalFilter: React.FC<CategoricalFilterProps> = ({
  items,
  availableItems,
  alwaysUseModal,
  selected,
  onChange,
  name,
  customModal,
  desktopScreen,
}) => {
  const [updatedFilters, setUpdatedFilter] = useState<Set<string>>(selected)

  useEffect(() => {
    if (desktopScreen) {
      setUpdatedFilter(selected)
    }
  }, [selected, desktopScreen])

  const handleChange = (key: string) => {
    const clone = new Set([...(selected ? selected : [])])
    if (clone.has(key)) {
      clone.delete(key)
    } else {
      clone.add(key)
    }
    onChange(clone)
  }

  const handleModalChange = (key: string, fireOnChange = false) => {
    const clone = new Set([...(updatedFilters ? updatedFilters : [])])
    if (clone.has(key)) {
      clone.delete(key)
    } else {
      clone.add(key)
    }
    setUpdatedFilter(clone)

    if (fireOnChange) {
      onChange(clone)
    }
  }

  const handleMobileChange = (key: string) => {
    handleModalChange(key, true)
  }

  const submitModal = () => {
    onChange(updatedFilters)
  }

  const handleOnlyClick = (key: string) => {
    const clone = new Set([...(selected ? selected : [])])

    clone.clear()
    clone.add(key)
    onChange(clone)
  }

  const handleModalOnlyClick = (key: string, fireOnChange = false) => {
    const clone = new Set([...(updatedFilters ? updatedFilters : [])])

    clone.clear()
    clone.add(key)
    setUpdatedFilter(clone)

    if (fireOnChange) {
      onChange(clone)
    }
  }

  const handleMobileOnlyClick = (key: string) => {
    handleModalOnlyClick(key, true)
  }

  const resetFilters = () => {
    setUpdatedFilter(selected)
  }

  const filterName = name ? name.replace(/filter/gi, '').toLowerCase() : ''

  return desktopScreen ? (
    <DesktopCategoricalFilter
      items={items}
      availableItems={availableItems}
      alwaysUseModal={alwaysUseModal}
      selected={selected}
      modalSelected={updatedFilters}
      resetFilters={resetFilters}
      name={name}
      customModal={customModal}
      filterName={filterName}
      handleOnlyClick={handleOnlyClick}
      handleModalOnlyClick={handleModalOnlyClick}
      handleChange={handleChange}
      handleModalChange={handleModalChange}
      submitModal={submitModal}
    />
  ) : (
    <MobileCategoricalFilter
      items={items}
      availableItems={availableItems}
      selected={updatedFilters}
      handleChange={handleMobileChange}
      name={name ?? ''}
      handleOnlyClick={handleMobileOnlyClick}
    />
  )
}

export default CategoricalFilter

export type { RecordType }
