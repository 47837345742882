'use client'

import { useEffect, useRef } from 'react'

import { styled } from 'styled-components'

import {
  Breakpoints,
  Colors,
  Shadows,
} from '@b-stock/bstock-react/design-system'

import { ActiveBar } from '@components/AppliedFilters'

export const Sticky = styled.div`
  --paddingX: 1.5rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @media ${Breakpoints.min.medium} {
    --paddingX: 3rem;
  }

  @media ${Breakpoints.max.medium} {
    position: sticky;
    top: 0;

    &.stuck {
      width: calc(100% + calc(var(--paddingX) * 2));
      padding: 1.5rem var(--paddingX);
      margin: -1.5rem calc(var(--paddingX) * -1);
      z-index: 1000;
      background: ${Colors.Semantic.Neutral.white};
      ${Shadows.Bottom}

      ${ActiveBar} {
        display: none;
      }
    }
  }
`

const Observer = styled.div`
  height: 0.125rem;
  width: 0.125rem;
  position: absolute;
`

type FiltersTopBar = {
  children: React.ReactNode
}

const FiltersTopBar = ({ children }: FiltersTopBar) => {
  const topBarRef = useRef<HTMLDivElement>(null)
  const observerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const header = topBarRef.current
    const target = observerRef.current

    if (header && target) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          header.classList.toggle('stuck', !entry.isIntersecting)
        },
        {
          threshold: 0,
        }
      )

      observer.observe(target)

      return () => {
        observer.disconnect()
      }
    }
  }, [])

  return (
    <>
      <Observer ref={observerRef} />
      <Sticky ref={topBarRef} role="banner">
        {children}
      </Sticky>
    </>
  )
}

export default FiltersTopBar
