import { useEffect, useRef } from 'react'

import { useRouter, useSearchParams, usePathname } from 'next/navigation'

import type { AllListingsFilters } from '@b-stock/search-api-client'

import type {
  SearchState,
  SearchAction,
} from '@components/BaseSearchProvider/types'
import stableStringify from '@helpers/stableStringify'

import defaultState from './defaultState'
import type { AuctionFiltersState } from './types'
import {
  composeAuctionStateFromQueryParams,
  composeUrlParamsFromAuctionState,
} from './utils'

/**
 * This hook maintains consistency between query params and the internal
 * search state. It watches both the state and the set of query params, and
 * updates the other when one of them changes. When the state changes, a
 * shallow navigation is performed, meaning we don't go through next's data
 * loading logic, and a new entry is entered in the browser history. When
 * the url changes (e.g. the user hits the back button) we update the state
 * triggering a re-query (possibly hitting react-query's cache). The use of
 * `stableStringify` is significant here because both state and `router.query`
 * can change object identity without a change in any value.
 */
export const useAuctionStateURLRectification = (
  state: SearchState<AuctionFiltersState>,
  dispatch: (action: SearchAction<AuctionFiltersState>) => void,
  forceFilters?: Partial<AuctionFiltersState>,
  allFilters?: AllListingsFilters
) => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const isInitialLoad = useRef(true)
  const filterKeys = Object.keys(defaultState.filters)

  useEffect(() => {
    if (isInitialLoad.current && searchParams) {
      const initialState = composeAuctionStateFromQueryParams(
        Object.fromEntries(searchParams.entries()),
        allFilters
      )
      dispatch({
        type: 'SET_STATE',
        state: initialState,
      })
      isInitialLoad.current = false
    }
  }, [])

  // Rectify URL when search state is updated
  useEffect(() => {
    const urlParams = composeUrlParamsFromAuctionState(
      state,
      Object.keys(forceFilters ?? {}),
      allFilters
    )

    if (searchParams) {
      const propagatedQuery = Object.fromEntries(searchParams.entries())
      const updatedQuery = new URLSearchParams()

      Object.entries(propagatedQuery).forEach(([key, value]) => {
        if (filterKeys.includes(key) && !(key in urlParams)) {
          updatedQuery.delete(key)
        } else if (!filterKeys.includes(key)) {
          updatedQuery.set(key, value)
        }
      })

      Object.entries(urlParams).forEach(([key, value]) => {
        updatedQuery.set(key, value)
      })

      router.push(
        `${pathname}?${updatedQuery.toString().replace(/\+/g, '%20')}`,
        { scroll: false }
      )
    }
  }, [stableStringify(state)])

  // Rectify state when URL is changed
  useEffect(() => {
    if (searchParams?.toString()) {
      dispatch({
        type: 'SET_STATE',
        state: composeAuctionStateFromQueryParams(
          Object.fromEntries(searchParams.entries()),
          allFilters
        ),
      })
    } else {
      // reset state if there are no search params
      dispatch({
        type: 'RESET_FILTERS',
      })
    }
  }, [searchParams?.toString()])
}
