import type { ReactNode } from 'react'
import { useMemo, useCallback } from 'react'

import type { AllListingsFilters } from '@b-stock/search-api-client'

import {
  useAuctionSearch,
  type AuctionFiltersState,
} from '@components/AuctionSearchProvider'

import AppliedFilterSection from './AppliedFilterSection'
import type { RecordType as CategoricalRecordType } from './CategoricalFilter'
import type { Range } from './NumericRangeFilter'
import { IntegerFormat, MSRPFormat, USDFormat } from './NumericRangeFilter'
import type { AppliedProps } from '../types'
import type { InternalNode } from './HierarchicalCategoricalFilter'

type KeysMatching<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never
}[keyof T]

const useFilterOptions = (
  availableFilterSet?: AllListingsFilters[keyof AllListingsFilters],
  toDisplayName?: (option: string) => ReactNode
): CategoricalRecordType[] => {
  return useMemo(() => {
    if (!availableFilterSet) {
      return []
    }

    return availableFilterSet
      .filter(
        (option): option is { value: string } =>
          typeof option.value === 'string'
      )
      .map((option) => ({
        key: option.value,
        displayName:
          toDisplayName && option.value
            ? toDisplayName(option.value)
            : option.value,
      }))
  }, [availableFilterSet, toDisplayName])
}

const createRangeAppliedComponent = (
  filterKey: KeysMatching<AuctionFiltersState, Range>,
  label: string,
  FormatComponent: React.ComponentType<{
    lower: number | null
    upper: number | null
  }>
) => {
  const Applied = ({ filtersState, updateFilters }: AppliedProps) => {
    const range = filtersState[filterKey]

    const value = useMemo(() => {
      if (!range || range.filter(Boolean).length === 0) {
        return null
      }

      const [lower, upper] = range

      return {
        key: filterKey,
        name: <FormatComponent lower={lower || 0} upper={upper} />,
      }
    }, [range])

    const onRemove = () => {
      updateFilters({ [filterKey]: [null, null] })
    }

    if (!value) {
      return <></>
    }

    return (
      <AppliedFilterSection
        label={label}
        values={[value]}
        onRemove={onRemove}
      />
    )
  }

  return Applied
}

const createNumericRangeAppliedComponent = (
  filterKey: KeysMatching<AuctionFiltersState, Range>,
  label: string
) => {
  return createRangeAppliedComponent(filterKey, label, IntegerFormat)
}

const createCurrencyRangeAppliedComponent = (
  filterKey: KeysMatching<AuctionFiltersState, Range>,
  label: string
) => {
  return createRangeAppliedComponent(filterKey, label, MSRPFormat)
}

const createUSDCurrencyRangeAppliedComponent = (
  filterKey: KeysMatching<AuctionFiltersState, Range>,
  label: string
) => {
  return createRangeAppliedComponent(filterKey, label, USDFormat)
}

// TODO: If data changes in the future to only send "New", this can be removed
export const formatConditionValues = (
  values: AuctionFiltersState['condition'],
  allConditions: NonNullable<AllListingsFilters['condition']>
): AuctionFiltersState['condition'] => {
  // This checks the filters coming in from the search API. If the listings
  // filters data does not send us "Brand New", we should NOT convert "New"
  // back to "Brand New"
  const shouldFormat = allConditions.find(
    (condition) => condition.value === 'Brand New'
  )
  return values.map((value) =>
    value === 'Brand New'
      ? 'New'
      : shouldFormat && value === 'New'
        ? 'Brand New'
        : value
  )
}

type RegionItems = {
  value: string
  position: string[]
  count: number
}

const buildRegionTree = (regions: RegionItems[]): InternalNode[] => {
  const root: { [key: string]: InternalNode } = {}

  regions.forEach((region) => {
    const { position, value } = region
    const [parentName, childName, grandchildName] = position

    if (parentName === 'United States' && position.length === 3) {
      if (!root[parentName]) {
        root[parentName] = {
          nodeType: 'INTERNAL',
          key: parentName,
          displayName: parentName,
          children: [],
        }
      }

      let childNode = root[parentName].children.find(
        (node) => node.displayName === childName
      )
      if (!childNode) {
        childNode = {
          nodeType: 'INTERNAL',
          key: childName,
          displayName: childName,
          children: [],
        }
        root[parentName].children.push(childNode)
      }

      const grandChildNode = {
        nodeType: 'LEAF' as InternalNode['nodeType'],
        key: value,
        displayName: grandchildName,
        children: [],
      }
      childNode.children.push(grandChildNode)
    } else if (position.length === 2) {
      const [parent, child] = position

      if (!root[parent]) {
        root[parent] = {
          nodeType: 'INTERNAL',
          key: parent,
          displayName: parent,
          children: [],
        }
      }

      const childNode = {
        nodeType: 'LEAF' as InternalNode['nodeType'],
        key: value,
        displayName: child,
        children: [],
      }
      root[parent].children.push(childNode)
    }
  })

  const sortTree = (nodes: InternalNode[]): InternalNode[] => {
    return nodes
      .map((node) => ({
        ...node,
        children: sortTree(node.children),
      }))
      .sort((a, b) => {
        if (a.displayName === 'United States') return -1 // United States first
        if (b.displayName === 'United States') return 1 // All other regions
        return a.displayName.localeCompare(b.displayName)
      })
  }

  return sortTree(Object.values(root))
}

const simplifyRegionFilter = (regions: string[], allRegions: RegionItems[]) => {
  const regionTree = buildRegionTree(allRegions)

  regionTree.forEach((node) => {
    const grandChildrenKeys =
      node.key === 'United States'
        ? node.children.flatMap((c) => c.children.map((gc) => gc.key))
        : []
    const childrenKeys = node.children.map((c) => c.key)

    const hasEveryRegion = (keys: string[]) =>
      keys.every((k) => regions.includes(k))

    const filterKeys = (keys: string[], simplifiedKey = node.key) => {
      const filteredRegions = regions.filter((f) => !keys.includes(f))
      return [...filteredRegions, simplifiedKey]
    }

    // if all states are selected, simplify to just 'United States'
    if (grandChildrenKeys.length > 0 && hasEveryRegion(grandChildrenKeys)) {
      regions = filterKeys(grandChildrenKeys)
    }
    // if all states within a US region are selected, simplify to just the US region
    else if (node.key === 'United States') {
      const usRegionChildrenKeys = node.children.reduce<{
        [key: string]: string[]
      }>((acc, child) => {
        acc[child.key] = child.children.map((c) => c.key)
        return acc
      }, {})

      Object.entries(usRegionChildrenKeys).forEach(([key, childKeys]) => {
        if (hasEveryRegion(childKeys)) {
          regions = filterKeys(childKeys, key)
        }
      })
    }
    // if all countries within a region are selected, simplify to just the region
    else if (hasEveryRegion(childrenKeys)) {
      regions = filterKeys(childrenKeys)
    }
  })

  regions = regions
    .map((r) => {
      const position = allRegions.find((region) => region.value === r)?.position
      // nice name, eg: "Italy" instead of "/IT" or "Kansas" instead of "KS/US"
      if (position) {
        return position[position.length - 1]
      }

      const isShorthandName = (name: string): boolean => {
        const parts = name.split('/')
        return parts.every((part) => part === '' || part.length === 2)
      }

      // if what we're left with is an "ugly" name because the current regions
      // state includes a filter (e.g. AL/US) that is no longer in the
      // allFilters.regions values, then just strip it out. If it's a "nice"
      // name coming from a saved or clicked on URL (e.g. /Alberta) and that
      // filter does not currently exist in the allFilters.regions values,
      // then return it without the "/"
      return isShorthandName(r) ? null : r.replace('/', '')
    })
    .filter((r) => r !== null)

  return regions
}

const desimplifyRegionFilter = (
  regions: string[],
  allRegions: RegionItems[]
) => {
  const regionTree = buildRegionTree(allRegions)

  const findNode = (
    region: string,
    nodes: InternalNode[]
  ): InternalNode | null => {
    for (const node of nodes) {
      if (node.key === region || node.displayName === region) {
        return node
      }
    }

    for (const node of nodes) {
      if (node.children) {
        const result = findNode(region, node.children)
        if (result) {
          return result
        }
      }
    }

    return null
  }

  const desimplify = (region: string) => {
    const regionNode = findNode(region, regionTree)

    if (!regionNode) {
      return [region.includes('/') ? region : `/${region}`]
    }

    if (regionNode.nodeType === 'LEAF') {
      return [regionNode.key]
    }

    return regionNode.children.flatMap((child) => {
      if (child.nodeType === 'LEAF') {
        return [child.key]
      }

      return child.children.map((grandChild) => grandChild.key)
    })
  }

  return regions.flatMap(desimplify)
}

const maybeDesimplifyFilter = (
  filters: Partial<AuctionFiltersState>,
  allFilters: AllListingsFilters
) => {
  const regionFilter = filters.region

  if (!regionFilter) {
    return filters
  }

  const desimplified = desimplifyRegionFilter(
    regionFilter,
    allFilters.region as RegionItems[]
  )

  return {
    ...filters,
    region: desimplified,
  }
}

const regionFilterContribution = (
  value: string[],
  allRegions: RegionItems[]
) => {
  const simplifiedValue = simplifyRegionFilter(value, allRegions)
  return simplifiedValue.length
}

const createCategoricalAppliedComponent = (
  filterKey: KeysMatching<AuctionFiltersState, string[]>,
  label: string,
  toDisplayName: (s: string) => React.JSX.Element
) => {
  const Applied = ({ filtersState, updateFilters }: AppliedProps) => {
    let filter = filtersState[filterKey]
    const searchCtx = useAuctionSearch()

    if (filter && filterKey === 'region') {
      const allRegions = (searchCtx.data?.allFilters.region ||
        []) as RegionItems[]
      filter = simplifyRegionFilter(filter, allRegions)
    }

    const values = useMemo(() => {
      if (!filter) {
        return []
      }

      return filter.map((cat: string) => ({
        key: cat,
        name: toDisplayName(cat),
      }))
    }, [filter])

    const onRemove = useCallback(
      (keyToRemove: string) => {
        const newValue = filter.filter((key: string) => {
          return keyToRemove !== key
        })
        updateFilters({ [filterKey]: newValue })
      },
      [filter, updateFilters]
    )

    return (
      <AppliedFilterSection label={label} values={values} onRemove={onRemove} />
    )
  }

  return Applied
}

export {
  useFilterOptions,
  // presetAndFreeRangesFromMixedRanges,
  createNumericRangeAppliedComponent,
  createCurrencyRangeAppliedComponent,
  createUSDCurrencyRangeAppliedComponent,
  createCategoricalAppliedComponent,
  buildRegionTree,
  type RegionItems,
  regionFilterContribution,
  simplifyRegionFilter,
  desimplifyRegionFilter,
  maybeDesimplifyFilter,
}
