'use client'

import {
  useReducer,
  useContext,
  useMemo,
  createContext,
  type Reducer,
  type ReactNode,
} from 'react'

import type { AllListingsFilters } from '@b-stock/search-api-client'

import {
  maybeDesimplifyFilter,
  regionFilterContribution,
  type RegionItems,
} from '@components/auctionFilters/shared/filterUtils'
import listingSearchQuery from '@queries/listingSearchQuery'

import defaultState from './defaultState'
import type { AuctionFiltersState, AuctionSearchContextValue } from './types'
import { useAuctionStateURLRectification } from './useStateURLRectification'
import { composeQueryFromAuctionState } from './utils'
import type { Range } from '../auctionFilters/shared/commonTypes'
import type {
  SearchState,
  SearchAction,
  AllListingsSortByEnum,
  AllListingsSortOrderEnum,
} from '../BaseSearchProvider/types'

const defaultContextValue = {
  state: defaultState,
  data: null,
  isLoading: true,
  setQuery: (query: string) => {
    console.log('setQuery', query)
    // AppRouter.push(`/?q=${query}`)
  },
  setPage: () => null,
  setSort: () => null,
  setFilter: () => null,
  getActiveFilterCount: () => 0,
  updateFilters: () => null,
  resetFilters: () => null,
}

const filterContribution = (value: string[]) => value.length
const rangeContribution = ([min, max]: Range) =>
  min === null && max === null ? 0 : 1
const distanceFilterContribution = (value: number | null) =>
  value === null ? 0 : 1

const getActiveFilterCount = (
  filters: AuctionFiltersState,
  allFilters: AllListingsFilters
): number => {
  return (Object.keys(filters) as (keyof AuctionFiltersState)[]).reduce(
    (acc: number, key): number => {
      switch (key) {
        case 'storefront':
        case 'categories':
        case 'condition':
        case 'inventoryType':
        case 'listingType':
        case 'buyingFormat':
        case 'shipmentType':
        case 'productType':
        case 'warehouseLocation':
          return acc + filterContribution(filters[key])
        case 'currentBidRanges':
          return acc + rangeContribution(filters[key])
        case 'region':
          return (
            acc +
            regionFilterContribution(
              filters[key],
              allFilters.region as RegionItems[]
            )
          )
        case 'distance':
          return acc + distanceFilterContribution(filters[key])
      }
    },
    0
  )
}

const SearchContext =
  createContext<AuctionSearchContextValue>(defaultContextValue)

const reducer: Reducer<
  SearchState<AuctionFiltersState>,
  SearchAction<AuctionFiltersState>
> = (state, action) => {
  switch (action.type) {
    case 'SET_STATE':
      return action.state
    case 'SET_QUERY':
      return {
        ...state,
        query: action.query,
        page: 1,
      }
    case 'SET_SORT':
      return {
        ...state,
        page: 1,
        sortBy: action.sortBy,
        sortOrder: action.sortOrder,
      }
    case 'SET_PAGE':
      return {
        ...state,
        page: action.page,
      }
    case 'UPDATE_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters,
        },
      }
    case 'RESET_FILTERS':
      return {
        ...state,
        filters: defaultState.filters,
      }
  }
}

type SearchProviderProps = {
  initialState?: SearchState<AuctionFiltersState>
  /**
   * Filters that will always be applied and can't be changed. Won't be counted
   * as applied for presentation of active filters.
   */
  forceFilters?: Partial<AuctionFiltersState>
  children: ReactNode
  allFilters: AllListingsFilters
}

const AuctionSearchProvider = ({
  forceFilters,
  children,
  allFilters,
}: SearchProviderProps) => {
  const [state, dispatch] = useReducer(reducer, defaultState)
  const params = composeQueryFromAuctionState({
    ...state,
    filters: { ...state.filters, ...forceFilters },
    allFilters,
  })

  const { data, isLoading, error } = listingSearchQuery.useQuery({
    params,
  })

  useAuctionStateURLRectification(state, dispatch, forceFilters, allFilters)

  const contextValue = useMemo(
    () => ({
      state,
      data: data || null,
      isLoading,
      error,
      getActiveFilterCount: () => {
        return getActiveFilterCount(state.filters, allFilters)
      },
      setQuery: (query: string) =>
        dispatch({
          type: 'SET_QUERY',
          query,
        }),
      setPage: (page: number) =>
        dispatch({
          type: 'SET_PAGE',
          page,
        }),
      setSort: (
        sortBy: AllListingsSortByEnum,
        sortOrder: AllListingsSortOrderEnum
      ) =>
        dispatch({
          type: 'SET_SORT',
          sortBy,
          sortOrder,
        }),
      updateFilters: (filters: Partial<AuctionFiltersState>) =>
        dispatch({
          type: 'UPDATE_FILTERS',
          filters: maybeDesimplifyFilter(filters, allFilters),
        }),
      resetFilters: () => dispatch({ type: 'RESET_FILTERS' }),
    }),
    [state, data, isLoading, error]
  )

  return (
    <SearchContext.Provider value={contextValue}>
      {children}
    </SearchContext.Provider>
  )
}

const useAuctionSearch = () => useContext(SearchContext)

export {
  useAuctionSearch,
  SearchContext,
  defaultContextValue,
  getActiveFilterCount,
}

export default AuctionSearchProvider
