import { useMemo } from 'react'

import {
  createCategoricalAppliedComponent,
  buildRegionTree,
  type RegionItems,
} from '../../shared/filterUtils'
import HierarchicalCategoricalFilter from '../../shared/HierarchicalCategoricalFilter'
import type { AuctionFilter } from '../../types'

const RegionFilter: AuctionFilter<Set<string>> = ({
  items,
  onChange,
  value,
  desktopScreen,
}) => {
  const nodes = useMemo(
    () => buildRegionTree((items || []) as RegionItems[]),
    [items]
  )
  return (
    <HierarchicalCategoricalFilter
      label="Region"
      nodes={nodes}
      selected={value}
      setSelected={onChange}
      desktopScreen={desktopScreen}
    />
  )
}

RegionFilter.label = 'Filters.location'

RegionFilter.getOwnProps = ({ state, data, updateFilters }) => {
  return {
    filterKey: 'region',
    items: data?.allFilters.region || [],
    value: new Set(state.filters.region),
    onChange: (value: Set<string>) => updateFilters({ region: [...value] }),
  }
}

RegionFilter.Applied = createCategoricalAppliedComponent(
  'region',
  RegionFilter.label,
  (region: string) => <span>{region}</span>
)

export default RegionFilter
