import { styled } from 'styled-components'

import { useAccessToken } from '@b-stock/bstock-next'

import SavedSearchButton from '@components/savedSearch/SavedSearchButton'

import FilterContainer from './shared/FilterContainer'
import type { Filter, ContextType } from './types'

type SearchFiltersProps<ContextValue extends ContextType, T> = {
  className?: string
  filters: Filter<T, ContextValue>[]
  searchCtx: ContextValue
}

const Container = styled.div`
  max-width: 100%;
  position: relative;
`

const SearchFilters = <ContextValue extends ContextType, T>({
  className,
  filters,
  searchCtx,
}: SearchFiltersProps<ContextValue, T>) => {
  // const authState = useAuthState()
  const accessToken = useAccessToken()
  const isLoggedIn = !!accessToken
  const { isLoading } = searchCtx

  return (
    !isLoading && (
      <Container className={className} data-testid="SearchFilters">
        <SavedSearchButton />

        {filters.map((FilterComp) => {
          const props = FilterComp.getOwnProps(searchCtx)

          // TODO: review it, The applied filters checking should be refactored
          const shouldDisplayFilter =
            Object.keys(props.value || {}).length ||
            props.value === null || // it is empty range value
            Object.keys(props.items || {}).length

          const showForCurrentUser = props.forLoggedUsersOnly
            ? isLoggedIn
            : true
          const propsOnChange = props.onChange
          props.onChange = (value) => {
            propsOnChange(value)
            searchCtx.setPage(1)
          }
          return shouldDisplayFilter && showForCurrentUser ? (
            <FilterContainer
              key={FilterComp.label}
              label={FilterComp.label}
              expandedOnInit
            >
              <FilterComp {...props} desktopScreen />
            </FilterContainer>
          ) : null
        })}
      </Container>
    )
  )
}

export default SearchFilters
