import { keepPreviousData } from '@tanstack/react-query'

import type {
  BidListItem,
  AccountUniqueBids,
  AuctionBuyNow,
} from '@b-stock/auction-api-client'
import {
  getBidsClient,
  canReadAsBuyer,
  buildAxiosRequestConfig,
  makeOptionallyAuthenticatedQuery,
  fetchAllResultsById,
} from '@b-stock/bstock-next'
import type {
  AllListingsListing,
  AllListings200Response,
  AllListingsApiAllListingsRequest,
  AllListingsFilters,
} from '@b-stock/search-api-client'

import getAllListingsSearchClient from './getAllListingsSearchClient'

export enum BidStatus {
  WINNING = 'WINNING',
  WON = 'WON',
  LOSING = 'LOSING',
  LOST = 'LOST',
  NO_BID = 'NO_BID',
}

export type SearchResult = AllListingsListing & {
  bid?: BidListItem
  bidStatus: BidStatus
  buyNow?: AuctionBuyNow
  retailPrice?: number | null
}

export type SearchResultsPage = Omit<AllListings200Response, 'listings'> & {
  listings: SearchResult[]
  allFilters: AllListingsFilters
}

export type SearchParams = {
  params: AllListingsApiAllListingsRequest & {
    query?: string | null
  }
  fetchFilters?: boolean
}

const getBidsQueryParameters = (ids: string[], limit: number) => ({
  auctionId: ids.join(','),
  limit,
})

const bidsResponseToResults = (response: AccountUniqueBids) => response.bids

const getBidStatus = (bid?: BidListItem): BidStatus => {
  if (!bid) return BidStatus.NO_BID

  if (bid.auction.closed) {
    return bid.winning ? BidStatus.WON : BidStatus.LOST
  } else {
    return bid.winning ? BidStatus.WINNING : BidStatus.LOSING
  }
}

// Drives ALL AUCTIONS and Seller Auctions pages
const getSearchData = async (
  accessToken: string | null,
  { params, fetchFilters = true }: SearchParams
): Promise<SearchResultsPage> => {
  const searchClient = getAllListingsSearchClient()
  const bidsClient = getBidsClient()
  const axiosParams = buildAxiosRequestConfig({ accessToken })

  const { query, ...searchParams } = params
  const [{ data: searchResponse }, { data: filtersResponse }] =
    await Promise.all([
      searchClient.allListings(searchParams, axiosParams),
      fetchFilters
        ? searchClient.allListingsFilters({}, axiosParams)
        : { data: {} },
    ])

  const auctionIds = searchResponse.listings
    .map((result) => result.auctionId)
    .filter((x) => x)

  const bidsByAuctionId: Partial<Record<string, BidListItem>> = {}

  if (canReadAsBuyer(accessToken)) {
    const bids = await fetchAllResultsById({
      apiClient: bidsClient,
      query: bidsClient.getAccountUniqueBids,
      ids: auctionIds,
      getQueryParameters: getBidsQueryParameters,
      responseToResults: bidsResponseToResults,
      axiosOptions: buildAxiosRequestConfig({ accessToken }),
      limit: 100,
    })
    bids.forEach((bid) => {
      if (bid.auctionId) {
        bidsByAuctionId[bid.auctionId] = bid
      }
    })
  }

  const augmentedResult: SearchResult[] = searchResponse.listings.map(
    (result: AllListingsListing) => {
      const bid = bidsByAuctionId[result.auctionId || '']
      return {
        ...result,
        bidStatus: getBidStatus(bid),
        ...(bid ? { bid } : {}),
      }
    }
  )

  return {
    listings: augmentedResult,
    total: searchResponse.total,
    limit: searchResponse.limit,
    offset: searchResponse.offset,
    allFilters: filtersResponse,
  }
}

const listingSearchQuery = makeOptionallyAuthenticatedQuery<
  [SearchParams],
  SearchResultsPage
>('search', getSearchData, {
  placeholderData: keepPreviousData,
})

export default listingSearchQuery
